<template>
  <div class="create_preview">
    <div class="preview_tip">
      <template
          v-if="
          previewNo.groupOneNameList.length > 0 ||
            previewNo.groupTwoNameList.length > 0
        "
      >
        <h5 class="tip_h5">
          <span class="tip_icon"></span>{{ $t("cases.createPreview.wwcts") }}
        </h5>
        <ul class="p_tip_ul">
          <li v-if="previewNo.groupOneNameList.length > 0" class="p_tip_li">
            <h5 class="p_tip_l_h">
              {{ $t("cases.createPreview.jzzl") }}
            </h5>
            <div class="p_tip_l_box">
              <span
                  v-for="item in previewNo.groupOneNameList"
                  :key="`${$t('cases.createPreview.jzzl')}_${item}`"
                  @click="groupClick(item, '1')"
              >{{ item }}</span
              >
            </div>
          </li>
          <li v-if="previewNo.groupTwoNameList.length > 0" class="p_tip_li">
            <h5 class="p_tip_l_h">
              {{ $t("cases.createPreview.yxzlsm") }}
            </h5>
            <div class="p_tip_l_box">
              <span
                  v-for="item in previewNo.groupTwoNameList"
                  :key="`${$t('cases.createPreview.yxzlsm')}_${item}`"
                  :data-tit="item"
                  data-type="2"
                  @click="groupClick(item, '2')"
              >{{ item }}</span
              >
            </div>
          </li>
        </ul>
      </template>
      <template v-if="!queer">
        <h2 class="tip_h5">
          <span class="tip_icon"></span>{{ $t("cases.createPreview.wwcts") }}
        </h2>
        <div class="queer">
          <span class="mr10" @click="goBackQueer('1')">
            {{ $t("cases.createPreview.jzzl") }}
          </span>
          <span @click="goBackQueer('2')">
            {{ $t("cases.createPreview.yxzlsm") }}
          </span>
        </div>
      </template>
    </div>
    <div
        v-if="
        previewNo.groupOneNameList.length < 1 &&
          previewNo.groupTwoNameList.length < 1 &&
          queer
      "
        class="preview_box"
    >
      <h5 class="tip_h5">
        <span class="tip_icon tip_icon_ok"></span>
        {{ $t("cases.createPreview.bltxwz") }}
      </h5>
      <div class="preview_box_content">
        <div class="flex-y-c mt2rem mb2rem">
          <p class="to_clinical" @click.stop="goClinical">
            {{ $t("common.lcph.cklcph") }}
          </p>
          <p class="fz14 main_theme_color_999">
            {{ $t("cases.createPreview.ggphtext") }}
          </p>
        </div>
        <ul class="preview_box_ul">
          <public-cases-user
              :caseInfo="details.caseInfo"
              :userInfo="userInfo"
          />
          <PreviewDiagnosis
              :key="1"
              :casesData="casesData"
              :commonTeeth="commonTeeth"
              :crowdTeethList="crowdTeethList"
              :details="details"
              :productType="$route.query.productType"
              :uploadIntervalList="uploadIntervalList"
              :veneerList="commonVeneerList"
          />
          <public-cases-pic
              :details="details"
              :surfacePicList="
              routeQueryType === 'F' ? yueyaPicList : surfacePicList
            "
              :xPicList="xPicList"
          />
          <public-teeth-model
              :details="details"
              :fileList="details.attachmentList"
          ></public-teeth-model>
        </ul>
        <div class="preview_foo">
          <p
              class="submit_btn curp flex-x-y-c fz14 main_theme_color_333"
              style="width: 2rem; height: 0.48rem"
              @click="submitCase"
          >
            {{ $t("common.message.tj") }}
          </p>
          <p class="mt10 main_theme_color_red fz14">
            {{ $t("common.message.qqrww") }}
          </p>
        </div>
      </div>
    </div>
    <FullLoading v-show="isLoading"/>
  </div>
</template>

<script>
import FullLoading from "components/full-loading/full-loading";
import PublicCasesUser from "../publicCases/casesUser";
import PublicCasesDiagnosis from "../publicCases/casesDiagnosis";
import PublicCasesPic from "../publicCases/casesPic";
import {
  detailCurePlan,
  toCompleteCurePlan,
  doctorSubmitCurePlan,
} from "common/api/cases";
import {notifyMsg} from "common/js/util";
import PreviewDiagnosis from "../../../components/treatment-table/preview-table/index";
import {filter, each, extend, map, isBoolean, split} from "lodash";
import PublicTeethModel from "../../../components/treatment-table/preview-teeth-model/index";
import {mapState} from "vuex";

const adultTeethList = [
  {number: 11},
  {number: 12},
  {number: 13},
  {number: 14},
  {number: 15},
  {number: 16},
  {number: 17},
  {number: 18},
  {number: 21},
  {number: 22},
  {number: 23},
  {number: 24},
  {number: 25},
  {number: 26},
  {number: 27},
  {number: 28},
  {number: 48},
  {number: 47},
  {number: 46},
  {number: 45},
  {number: 44},
  {number: 43},
  {number: 42},
  {number: 41},
  {number: 31},
  {number: 32},
  {number: 33},
  {number: 34},
  {number: 35},
  {number: 36},
  {number: 37},
  {number: 38},
];
let lang = localStorage.getItem("user_lang") || "zh_CN";
export default {
  data() {
    return {
      crowdTeethList: [], // 临面去釉 回显
      teethKeyObj: {},
      babyTeethKeyObj: {},
      previewNo: {
        groupTwoNameList: [],
        groupOneNameList: [],
      },
      copyCases: null,
      casesData: [],

      yysdTable: [],

      yueyaPicList: [
        {
          key: "sideFacePicture",
          name: this.$t("cases.createImage.zcmwxx"),
        },
        {
          key: "frontFacePicture",
          name: this.$t("cases.createImage.zmwxx"),
        },
        {
          key: "smilePicture",
          name: this.$t("cases.createImage.ycmwxx"),
        },
        {
          key: "bitePicture",
          name: this.$t("cases.createImage.yhx"),
        },
        {
          key: "openAndClosePicture",
          name: this.$t("cases.createImage.khx"),
        },
      ],

      surfacePicList: [
        {
          key: "sideFacePicture",
          name: this.$t("cases.createImage.cmx"),
        },
        {
          key: "frontFacePicture",
          name: this.$t("cases.createImage.zmx"),
        },
        {
          key: "smilePicture",
          name: this.$t("cases.createImage.wxx"),
        },
        {
          key: "upToothPicture",
          name: this.$t("cases.createImage.sylx"),
        },
        {
          key: "anteriorOverjetPicture",
          name: this.$t("cases.createImage.qyfgx"),
        },
        {
          key: "downToothPicture",
          name: this.$t("cases.createImage.xylx"),
        },
        {
          key: "mouthRightPicture",
          name: this.$t("cases.createImage.knzcyx"),
        },
        {
          key: "mouthFrontPicture",
          name: this.$t("cases.createImage.knzwx"),
        },
        {
          key: "mouthLeftPicture",
          name: this.$t("cases.createImage.knycyx"),
        },
      ],
      xPicList: [
        {key: "xrayCurvePicture", name: this.$t("cases.createImage.dcp")},
        {key: "xraySidePicture", name: this.$t("cases.createImage.dwp")},
      ],
      otherPic: "otherPicture",
      subParamsList: [
        {
          diagnosisForm: [],
        },
        {
          picParams: [],
        },
      ],
      details: {},
      userInfo: {},
      gapDiagnososObj: {
        [this.$t("cases.createDiagnosis.zyjzmb")]: 1,
        [this.$t("cases.createPreview.njzye")]: 2,
      },
      diagnosisObj: {
        [this.$t("cases.createPreview.zs")]: 1,
        [this.$t("cases.createDiagnosis.zyjzmb")]: 2,
        [this.$t("cases.createDiagnosis.yczkasfl")]: 3,
        [this.$t("cases.createDiagnosis.yczkchlx")]: 3,
        [this.$t("cases.createPreview.njzye")]: 4,
        [this.$t("cases.createPreview.jzgx")]: 8,
        [this.$t("cases.createPreview.zx")]: 13,
        [this.$t("cases.createPreview.sfphz")]: 16,
        [this.$t("cases.createDiagnosis.sfphzzzkdbw")]: 16,
        [this.$t("cases.createDiagnosis.sfphzzzkdzy")]: 16,
        [this.$t("cases.createPreview.hgj")]: 17,
        [this.$t("cases.createDiagnosis.backjclc")]: 15,
        [this.$t("cases.createDiagnosis.backjcqt")]: 15,
        [this.$t("cases.createDiagnosis.sfxybdjzq")]: 18,
      },
      veneerDiagnosisObj: {
        [this.$t("cases.createPreview.zs")]: 1,
        [this.$t("cases.createDiagnosis.zyjzmb")]: 2,
        [this.$t("cases.createDiagnosis.yczkasfl")]: 3,
        [this.$t("cases.createDiagnosis.yczkchlx")]: 3,
        [this.$t("cases.createPreview.njzye")]: 4,
        [this.$t("cases.createPreview.jzgx")]: 8,
        [this.$t("cases.createPreview.zx")]: 13,
        [this.$t("cases.createPreview.sfphz")]: 16,
        [this.$t("cases.createPreview.sfphz")]: 16,
        [this.$t("cases.createPreview.sfphzzzkdbw")]: 16,
        [this.$t("cases.createPreview.sfphzzzkdzy")]: 16,
        [this.$t("cases.createPreview.hgj")]: 17,
        [this.$t("cases.createDiagnosis.backjclc")]: 15,
        [this.$t("cases.createDiagnosis.backjcqt")]: 15,
        [this.$t("cases.createDiagnosis.sfxybdjzq")]: 18,
        [this.$t("cases.createDiagnosis.xfsj")]: 19,
        [this.$t("cases.createDiagnosis.xfsjsxyw")]: 19,
        [this.$t("cases.createDiagnosis.xfsjyysx")]: 19,
        [this.$t("cases.createDiagnosis.xfsjmbys")]: 19,
        [this.$t("cases.createDiagnosis.xfsjymwl")]: 19,
        [this.$t("cases.createDiagnosis.xfsjsjyq")]: 19,
      },
      youthDiagnosisObj: {
        [this.$t("cases.createPreview.zs")]: 1,
        [this.$t("cases.createDiagnosis.zyjzmb")]: 2,
        [this.$t("cases.createDiagnosis.yczkasfl")]: 3,
        [this.$t("cases.createDiagnosis.yczkchlx")]: 3,
        [this.$t("cases.createPreview.njzye")]: 5,
        [this.$t("cases.createDiagnosis.txt4")]: 6,
        [this.$t("cases.createDiagnosis.txt7")]: 9,
        [this.$t("cases.createDiagnosis.hxgx")]: 10,
        [this.$t("cases.createDiagnosis.txt12")]: 15,
        [this.$t("cases.createDiagnosis.backjclc")]: 17,
        [this.$t("cases.createDiagnosis.backjcqt")]: 17,
        [this.$t("cases.createDiagnosis.sfxybdjzq")]: 20,
        [this.$t("cases.createDiagnosis.by")]: 18,
        [this.$t("cases.createDiagnosis.txt16")]: 19,
        [this.$t("cases.createDiagnosis.sfxybdjzq")]: 20,
      },
      babyDiagnosisObj: {
        [this.$t("cases.createPreview.zs")]: 1,
        [this.$t("cases.createDiagnosis.zyjzmb")]: 2,
        [this.$t("cases.createDiagnosis.blxg")]: 3,
        [this.$t("cases.createPreview.njzye")]: 4,
        [this.$t("cases.createPreview.jzgx")]: 8,
        [this.$t("cases.createDiagnosis.hxgx")]: 9,
        [this.$t("cases.createPreview.zx")]: 14,
        [this.$t("cases.createDiagnosis.sfxybdjzq")]: 18,
      },
      braceDiagnosisObj: {
        [this.$t("cases.createDiagnosis.exgjsfczyc")]: "1",
        [this.$t("cases.createDiagnosis.yzsfzc")]: "2",
      },
      yysdDiagnosisObj: {
        [this.$t("cases.createDiagnosis.lczkchlx")]: "3",
        [this.$t("cases.createDiagnosis.lczkyhgx")]: "3",
        [this.$t("cases.createDiagnosis.lczkxljx")]: "3",
        [this.$t("cases.createDiagnosis.lczkycwt")]: "3",
        [this.$t("cases.createDiagnosis.sxyw")]: "4",
        [this.$t("cases.createDiagnosis.yysx")]: "5",
        [this.$t("cases.createDiagnosis.mbyase")]: "6",
        [this.$t("cases.createDiagnosis.mblx")]: "7",
        [this.$t("cases.createDiagnosis.mbtmd")]: "8",
        [this.$t("cases.createDiagnosis.cllx")]: "9",
        [this.$t("cases.createDiagnosis.sfby")]: "10",
        [this.$t("cases.createDiagnosis.tmhdyq")]: "11",
        [this.$t("cases.createDiagnosis.ymwl")]: "12",
        [this.$t("cases.createDiagnosis.sjyq")]: "13",
      },
      curePlanId: "",
      isLoading: false,
      queer: true,
      previewList: [],
      commonTeeth: [],
      commonVeneerList: [],
      uploadIntervalList: [],
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language,
    }),
    routeQueryType() {
      return this.$route.query.productType;
    },
    queryCaseId() {
      return this.$route.query.caseId;
    },
    queryCurePlanId() {
      return this.$route.query.curePlanId;
    },
    needRdt() {
      return sessionStorage.getItem("needRTD") === "1";
    },
    gap() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createDiagnosis.zyjzmb"),
          pList: [
            {
              filed: "toothProblemTwo",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem_i",
            },
            {
              filed: "toothOtherProblemTwo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 2,
          title: this.$t("cases.createPreview.njzye"),
          pList: [
            {
              filed: "toCorrectToothJaw",
              inquireKey: "to_correct_tooth_jaw",
            },
            {
              filed: "toCorrectToothUpjaw",
              inquireKey: "to_correct_tooth_upjaw",
              sublevel: true,
            },
            {
              filed: "toCorrectToothDownjaw",
              inquireKey: "to_correct_tooth_downjaw",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 3,
          title: this.$t("cases.createPreview.ycxx"),
          key: "teeth",
          teethType: 1,
          type: "gap",
          pList: [
            {
              showTeeth: true,
              type: "gap",
              key: "teeth",
              teethType: 1,
            },
          ],
        },
        {
          fIndex: 4,
          title: this.$t("cases.createDiagnosis.txt5"),
          teethType: 2,
          pList: [
            {
              filed: "nonMoveableTeeth",
              inquireKey: "non_moveable_teeth",
              type: "gap",
              key: "teeth",
              teethType: 2,
            },
          ],
        },
        {
          fIndex: 5,
          title: this.$t("cases.createDiagnosis.txt6"),
          key: "teeth",
          teethType: 3,
          type: "gap",
          pList: [
            {
              filed: "notAvailableAttachments",
              inquireKey: "not_available_for_attachments",
              type: "gap",
              key: "teeth",
              teethType: 3,
            },
          ],
        },
        {
          fIndex: 6,
          title: this.$t("cases.createPreview.jxzl"),
          pList: [
            {
              tit: this.$t("cases.createDiagnosis.txt4"),
              filed: "teeth",
              key: "interval",
            },
          ],
        },
        {
          fIndex: 7,
          title: this.$t("cases.createPreview.tssm"),
          pList: [
            {
              filed: "specialInstructions",
              type: "note",
            },
          ],
        },
      ];
    },
    adult() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createPreview.zs"),
          pList: [
            {
              filed: "toothProblem",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem",
            },
            {
              filed: "jawProblem",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem",
            },
            {
              filed: "toothOtherProblem",
              type: "note",
            },
          ],
        },
        {
          fIndex: 2,
          title: this.$t("cases.createDiagnosis.zyjzmb"),
          pList: [
            {
              filed: "toothProblemTwo",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem_two",
            },
            {
              filed: "jawProblemTwo",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem_two",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "toothOtherProblemTwo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 3,
          title: this.$t("cases.createDiagnosis.txt2"),
          pList: [
            {
              filed: "angleClassification",
              superiorText: this.$t("cases.createDiagnosis.asfl"),
              inquireKey: "angle_classification",
            },
            {
              filed: "sagittalMaxillomandibularClassification",
              superiorText: this.$t("cases.createDiagnosis.gxfl"),
              inquireKey: "sagittal_maxillomandibular_classification",
            },
            {
              filed: "clinicInfo",
              superiorText: this.$t("cases.createDiagnosis.chlx"),
              inquireKey: "clinic_info",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "clinicOtherInfo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 4,
          title: this.$t("cases.createPreview.njzye"),
          pList: [
            {
              filed: "toCorrectToothJaw",
              inquireKey: "to_correct_tooth_jaw",
            },
            {
              filed: "toCorrectToothUpjaw",
              inquireKey: "to_correct_tooth_upjaw",
              sublevel: true,
            },
            {
              filed: "toCorrectToothDownjaw",
              inquireKey: "to_correct_tooth_downjaw",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 5,
          title: this.$t("cases.createPreview.ycxx"),
          key: "teeth",
          teethType: 1,
          type: "adult",
          pList: [
            {
              showTeeth: true,
              type: "adult",
              key: "teeth",
              teethType: 1,
            },
          ],
        },
        {
          fIndex: 6,
          title: this.$t("cases.createDiagnosis.txt5"),
          teethType: 2,
          pList: [
            {
              filed: "nonMoveableTeeth",
              inquireKey: "non_moveable_teeth",
              type: "adult",
              key: "teeth",
              teethType: 2,
            },
          ],
        },
        {
          fIndex: 7,
          title: this.$t("cases.createDiagnosis.txt6"),
          key: "teeth",
          teethType: 3,
          type: "adult",
          pList: [
            {
              filed: "notAvailableAttachments",
              inquireKey: "not_available_for_attachments",
              type: "adult",
              key: "teeth",
              teethType: 3,
            },
          ],
        },

        {
          fIndex: 8,
          title: this.$t("cases.createPreview.jzgx"),
          pList: [
            {
              filed: "rightSagittalRelation",
              inquireKey: "right_sagittal_relation",
              secondLevel: this.$t("cases.createDiagnosis.you"),
            },
            {
              filed: "rightSagittalRelationNote",
              inquireKey: "improve_tip",
              threeOptions: [
                {
                  key: "rightSagittalRelationFar",
                  inquireKey: "precision_cut",
                  parentKey: "far_grind",
                },
                {
                  key: "rightSagittalRelationBitJump",
                  inquireKey: "precision_cut",
                  parentKey: "bit_jump",
                },
                {
                  key: "rightSagittalRelationExtraction",
                  inquireKey: "tooth_extraction",
                  parentKey: "tooth_extraction",
                },
              ],
              sublevel: true,
            },

            {
              filed: "leftSagittalRelation",
              inquireKey: "left_sagittal_relation",
              secondLevel: this.$t("cases.createPreview.zuo"),
            },
            {
              filed: "leftSagittalRelationNote",
              inquireKey: "improve_tip",
              threeOptions: [
                {
                  key: "leftSagittalRelationFar",
                  inquireKey: "precision_cut",
                  parentKey: "far_grind",
                },
                {
                  key: "leftSagittalRelationBitJump",
                  inquireKey: "precision_cut",
                  parentKey: "bit_jump",
                },
                {
                  key: "leftSagittalRelationExtraction",
                  inquireKey: "tooth_extraction",
                  parentKey: "tooth_extraction",
                },
              ],
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 9,
          title: this.$t("cases.createPreview.fg"),
          pList: [
            {
              filed: "coverInfo",
              inquireKey: "cover_info",
            },
          ],
        },
        {
          fIndex: 10,
          title: this.$t("cases.createPreview.fh"),
          pList: [
            {
              filed: "overbiteInfo",
              inquireKey: "overbite_info",
            },

            {
              filed: "overbiteInfoOpen",
              inquireKey: "correct_open",
              sublevel: true,
            },
            {
              filed: "overbiteInfoDeep",
              inquireKey: "correct_deep_cover",
              sublevel: true,
              threeOptions: [
                {
                  key: "overbiteInfoIntrudeAnterior",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front",
                },
                {
                  key: "overbiteInfoPosteriorsExrusion",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front_rise_back",
                },
              ],
            },

            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "overbiteOtherInfo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 11,
          title: this.$t("cases.createPreview.yd"),
          pList: [
            {
              filed: "frontToothInfo",
              inquireKey: "front_tooth_info",
            },

            {
              filed: "correctFrontReverse",
              inquireKeys: [
                "correct_front_reverse",
                "correct_front_reverse_two",
              ],
              parentText: this.$t("cases.createPreview.jzqyfh"),
              sublevel: true,
            },
            {
              filed: "correctFrontOpposite",
              inquireKeys: ["correct_front_opposite"],
              parentText: this.$t("cases.createPreview.jzqydrh"),
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 12,
          title: this.$t("cases.createPreview.hsh"),
          pList: [
            {
              filed: "backToothInfo",
              inquireKey: "back_tooth_info",
            },
            {
              filed: "backToothInfoCorrect",
              inquireKey: "back_tooth_info_correct",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 13,
          title: this.$t("cases.createPreview.zx"),
          pList: [
            {
              filed: "middleLine",
              inquireKey: "middle_line",
            },
            {
              filed: "upjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "upjawMiddleLine",
              superiorText: this.$t("cases.createDiagnosis.se"),
              isShowMM: true,
              sublevel: true,
            },

            {
              filed: "downjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "downjawMiddleLine",
              isShowMM: true,
              superiorText: this.$t("cases.createDiagnosis.xe"),
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 14,
          title: this.$t("cases.createPreview.jxzl"),
          pList: [
            {
              filed: "upjawGapCorrect",
              inquireKey: "updown_jaw_gap_correct",
              secondLevel: this.$t("cases.createPreview.she"),
            },
            {
              filed: "downjawGapCorrect",
              inquireKey: "updown_jaw_gap_correct",
              secondLevel: this.$t("cases.createPreview.xhe"),
            },
            {
              filed: "gapCorrect",
              inquireKey: "gap_correct",
              key: "reserve",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt4"),
              filed: "teeth",
            },
          ],
        },
        {
          fIndex: 15,
          title: this.$t("cases.createPreview.yjzl"),
          pList: [
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "acceptWisdomTeethExtraction",
                "acceptPremolarsExtraction",
                "acceptDecayedTeethExtraction",
                "acceptIpr",
              ],
              superiorText: "15.1 " + this.$t("cases.createDiagnosis.jcyjsx"),
            },
            {
              filed: "acceptWisdomTeethExtraction",
              inquireKey: "accept_wisdom_teeth_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbczc"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "acceptPremolarsExtraction",
              inquireKey: "accept_premolars_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbcqmy"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "acceptDecayedTeethExtraction",
              inquireKey: "accept_decayed_teeth_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbchy"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "acceptIpr",
              inquireKey: "accept_ipr",
              superiorText: this.$t("cases.createDiagnosis.hzsfjslmqy"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },

            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "undergoTeethRestoration",
                "implantAnchoragePlacement",
                "cbctPhotography",
                "orthodonticsTreatmentBefore",
              ],
              superiorText: "15.2 " + this.$t("cases.createDiagnosis.qtlczc"),
            },
            {
              filed: "undergoTeethRestoration",
              inquireKey: "undergo_teeth_restoration",
              superiorText: this.$t("cases.createDiagnosis.sfyycxf"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "implantAnchoragePlacement",
              inquireKey: "implant_anchorage_placement",
              superiorText: this.$t("cases.createDiagnosis.zssfjb"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "cbctPhotography",
              inquireKey: "cbct_photography",
              superiorText: this.$t("cases.createDiagnosis.zssfjbps"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "orthodonticsTreatmentBefore",
              inquireKey: "orthodontics_treatment_before",
              superiorText: this.$t("cases.createDiagnosis.hzsfyzjs"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar",
              ],
              superiorText: "15.3 " + this.$t("cases.createDiagnosis.jcyjjzfs"),
            },

            {
              identification: this.$t("cases.createDiagnosis.se"),
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar",
              ],
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml34 preview_pos_2"
                          : "preview_pos_8"
                      : this.needRdt
                          ? "ml38 preview_pos0"
                          : "preview_pos_8",
            },
            {
              filed: "upjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              parentName: "body_up",
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 preview_pos8"
                          : "ml38 mt12",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? "preview_pos8"
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },
            {
              filed: "upjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 preview_pos8"
                          : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? "preview_pos8"
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },
            {
              filed: "upjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              parentName: "body_up",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? "preview_pos8"
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 preview_pos8"
                          : "ml38 mt12",
            },
            {
              filed: "upjawSideAnterior",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.qy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml60",
              parentName: "body_up",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? ""
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },
            {
              filed: "upjawSideRight",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.ychy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml60",
              parentName: "body_up",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? ""
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },
            {
              filed: "upjawSideLeft",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.zchy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml60",
              parentName: "body_up",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? ""
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },
            {
              filed: "upjawFar",
              inquireKey: "upjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymy"),
              parentName: "body_up",
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 mt12"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 preview_pos8"
                          : "ml38 mt12",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? ""
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },

            {
              identification: this.$t("cases.createDiagnosis.xe"),
              childOptions: [
                "downjawExt",
                "downjawLip",
                "downjawSide",
                "downjawSideAnterior",
                "downjawSideRight",
                "downjawSideLeft",
                "downjawFar",
              ],
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml34 preview_pos_2"
                          : "preview_pos_8"
                      : this.needRdt
                          ? "ml38 preview_pos_8"
                          : "preview_pos_8",
            },
            {
              filed: "downjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 mt12"
                          : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                  lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 mt12"
                          : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                  lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 mt12"
                          : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                  lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawSideAnterior",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.qy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120"
                          : "ml60",
              parentName: "body_up",
            },
            {
              filed: "downjawSideRight",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.ychy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120"
                          : "ml60",
              parentName: "body_up",
            },
            {
              filed: "downjawSideLeft",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.zchy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120"
                          : "ml60",
              parentName: "body_up",
            },
            {
              filed: "downjawFar",
              inquireKey: "downjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 mt12"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 mt12"
                          : "ml38 mt12",
              parentName: "body_up",
            },

            {
              filed: "crowdCorrect",
              inquireKey: "crowd_correct",
              className: lang === "en_US" ? "ml34 mt12" : "ml38 mt12",
            },
            {
              filed: "toothExtraction",
              key: "teeth",
              teethType: 4,
              type: "adult",
              superiorText: this.$t("cases.createDiagnosis.by"),
              inquireKey: "tooth_extraction_options",
            },
          ],
        },
        {
          fIndex: 16,
          title: this.$t("cases.createPreview.sfphz"),
          pList: [
            {
              filed: "growNailFlag",
              inquireKey: "grow_nail_flag",
            },
            {
              filed: "upGrowNailPosition",
              inquireKey: "grow_nail_position",
              superiorText:
                  "16.1 " +
                  this.$t("cases.createDiagnosis.nzrzzkd") +
                  " - " +
                  this.$t("cases.createDiagnosis.se"),
            },
            {
              filed: "downGrowNailPosition",
              inquireKey: "grow_nail_position",
              superiorText:
                  "16.1 " +
                  this.$t("cases.createDiagnosis.nzrzzkd") +
                  " - " +
                  this.$t("cases.createDiagnosis.xe"),
            },
            {
              filed: "growNailPurpose",
              inquireKey: "grow_nail_purpose",
              superiorText:
                  "16.2 " + this.$t("cases.createDiagnosis.nzrzzkdzy"),
            },
            {
              filed: "growNailFlagNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 17,
          title: this.$t("cases.createPreview.hgj"),
          pList: [
            {
              filed: "underjawAbnormalFlag",
              inquireKey: "underjaw_abnormal_flag",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "underjawAbnormalFlagNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 18,
          title: this.$t("cases.createDiagnosis.sfxybdjzq"),
          pList: [
            {
              filed: "needPassiveAppliance",
              inquireKey: "need_passive_appliance",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "needPassiveApplianceNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 19,
          title: this.$t("cases.createPreview.tssm"),
          pList: [
            {
              filed: "specialInstructions",
              type: "note",
            },
          ],
        },
      ];
    },
    youthList() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createPreview.zs"),
          pList: [
            {
              filed: "toothProblem",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem_b",
            },
            {
              filed: "jawProblem",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem_b",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "toothOtherProblem",
              type: "note",
            },
          ],
        },
        {
          fIndex: 2,
          title: this.$t("cases.createDiagnosis.zyjzmb"),
          pList: [
            {
              filed: "toothProblemTwo",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem_two",
            },
            {
              filed: "jawProblemTwo",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem_two",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "toothOtherProblemTwo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 3,
          title: this.$t("cases.createDiagnosis.txt2"),
          pList: [
            {
              filed: "angleClassification",
              superiorText: this.$t("cases.createDiagnosis.asfl"),
              inquireKey: "angle_classification",
            },
            {
              filed: "sagittalMaxillomandibularClassification",
              superiorText: this.$t("cases.createDiagnosis.gxfl"),
              inquireKey: "sagittal_maxillomandibular_classification",
            },
            {
              filed: "clinicInfo",
              superiorText: this.$t("cases.createDiagnosis.chlx"),
              inquireKey: "clinic_info",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "clinicOtherInfo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 4,
          title: this.$t("cases.createDiagnosis.blxg"),
          pList: [
            {
              filed: "badHabits",
              inquireKey: "bad_habits",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "badHabitsNote",
              type: "note",
            },
          ],
        },
        // {
        //   fIndex: 5,
        //   title: this.$t('cases.createDiagnosis.jzzk'),
        //   pList: [
        //     {
        //       filed: 'dentition',
        //       inquireKey: 'dentition'
        //     },
        //   ]
        // },
        {
          fIndex: 5,
          title: this.$t("cases.createDiagnosis.txt3"),
          pList: [
            {
              filed: "toCorrectToothJaw",
              inquireKey: "to_correct_tooth_jaw",
            },
            {
              filed: "toCorrectToothUpjaw",
              inquireKey: "to_correct_tooth_upjaw_b",
              sublevel: true,
            },
            {
              filed: "toCorrectToothDownjaw",
              inquireKey: "to_correct_tooth_downjaw_b",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 6,
          title: this.$t("cases.createDiagnosis.txt4"),
          pList: [
            {
              showTeeth: true,
              type: "adult",
              key: "teeth",
              teethType: 1,
            },
          ],
        },
        {
          fIndex: 7,
          title: this.$t("cases.createDiagnosis.txt5"),
          key: "teeth",
          teethType: 2,
          pList: [
            {
              filed: "nonMoveableTeeth",
              inquireKey: "non_moveable_teeth",
              key: "teeth",
              type: "youth",
              teethType: 2,
            },
          ],
        },
        {
          fIndex: 8,
          title: this.$t("cases.createDiagnosis.txt6"),
          key: "teeth",
          teethType: 3,
          type: "youth",
          pList: [
            {
              filed: "notAvailableAttachments",
              inquireKey: "not_available_for_attachments",
              key: "teeth",
              type: "youth",
              teethType: 3,
            },
          ],
        },
        {
          fIndex: 9,
          title: this.$t("cases.createDiagnosis.txt7"),
          pList: [
            {
              filed: "sagittalRelation",
              inquireKey: "sagittal_relation",
            },
            {
              filed: "sagittalRelationMolar",
              inquireKey: "improve_molar",
              differentiateKey: "left",
              describeInputKey: "leftSagittalRelationMolarNote",
              sublevel: true,
            },
            {
              filed: "sagittalRelationMolar",
              inquireKey: "improve_molar",
              differentiateKey: "right",
              describeInputKey: "rightSagittalRelationMolarNote",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 10,
          title: this.$t("cases.createDiagnosis.hxgx"),
          pList: [
            {
              filed: "upHorizontalRelationship",
              inquireKey: "up_horizontal_relationship",
              secondLevel: this.$t("common.common.up"),
            },
            {
              filed: "upHorizontalRelationshipNote",
              inquireKey: "up_horizontal_expansion",
              describeInputKey: "upHorizontalRelationshipValue",
              isShowMM: true,
              sublevel: true,
            },

            {
              filed: "downHorizontalRelationship",
              inquireKey: "down_horizontal_relationship",
              secondLevel: this.$t("common.common.down"),
            },
            {
              filed: "downHorizontalRelationshipNote",
              inquireKey: "down_horizontal_expansion",
              describeInputKey: "downHorizontalRelationshipValue",
              isShowMM: true,
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 11,
          title: this.$t("cases.createDiagnosis.txt8"),
          pList: [
            {
              filed: "coverInfo",
              inquireKey: "cover_info",
            },
          ],
        },
        {
          fIndex: 12,
          title: this.$t("cases.createDiagnosis.txt9"),
          pList: [
            {
              filed: "overbiteInfo",
              inquireKey: "overbite_info",
            },

            {
              filed: "overbiteInfoOpen",
              inquireKey: "correct_open_b",
              sublevel: true,
              threeOptions: [
                {
                  key: "overbiteInfoExtrudeAnterior",
                  inquireKey: "updown_jaw",
                  parentKey: "extend_front_tooth",
                },
              ],
            },
            {
              filed: "overbiteInfoDeep",
              inquireKey: "correct_deep_cover_b",
              sublevel: true,
              threeOptions: [
                {
                  key: "overbiteInfoIntrudeAnterior",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front",
                },
                {
                  key: "overbiteInfoPosteriorsExrusion",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front_rise_back",
                },
              ],
            },

            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "overbiteOtherInfo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 13,
          title: this.$t("cases.createDiagnosis.qyfh"),
          pList: [
            {
              filed: "frontToothInfo",
              inquireKey: "front_tooth_info_b",
            },
            {
              filed: "correctFrontReverse",
              inquireKeys: [
                "correct_front_reverse",
                "correct_front_reverse_two",
              ],
              parentText: "",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 14,
          title: this.$t("cases.createDiagnosis.txt11"),
          pList: [
            {
              filed: "backToothInfo",
              inquireKey: "back_tooth_info",
            },
            {
              filed: "backToothInfoCorrect",
              inquireKey: "back_tooth_info_correct_b",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 15,
          title: this.$t("cases.createPreview.zx"),
          pList: [
            {
              filed: "middleLine",
              inquireKey: "middle_line",
            },
            {
              filed: "upjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "upjawMiddleLine",
              superiorText: this.$t("cases.createDiagnosis.se"),
              isShowMM: true,
              sublevel: true,
            },

            {
              filed: "downjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "downjawMiddleLine",
              isShowMM: true,
              superiorText: this.$t("cases.createDiagnosis.xe"),
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 16,
          title: this.$t("cases.createDiagnosis.txt13"),
          pList: [
            {
              tit: this.$t("cases.createDiagnosis.txt4"),
              filed: "teeth",
              key: "interval",
            },
          ],
        },
        {
          fIndex: 17,
          title: this.$t("cases.createDiagnosis.txt14"),
          pList: [
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "acceptWisdomTeethExtraction",
                "acceptPremolarsExtraction",
                "acceptDecayedTeethExtraction",
                "acceptIpr",
              ],
              superiorText: "17.1 " + this.$t("cases.createDiagnosis.jcyjsx"),
            },
            {
              filed: "acceptWisdomTeethExtraction",
              inquireKey: "accept_wisdom_teeth_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbczc"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "acceptPremolarsExtraction",
              inquireKey: "accept_premolars_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbcqmy"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "acceptDecayedTeethExtraction",
              inquireKey: "accept_decayed_teeth_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbchy"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "acceptIpr",
              inquireKey: "accept_ipr",
              superiorText: this.$t("cases.createDiagnosis.hzsfjslmqy"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },

            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "undergoTeethRestoration",
                "implantAnchoragePlacement",
                "cbctPhotography",
                "orthodonticsTreatmentBefore",
              ],
              superiorText: "17.2 " + this.$t("cases.createDiagnosis.qtlczc"),
            },
            {
              filed: "undergoTeethRestoration",
              inquireKey: "undergo_teeth_restoration",
              superiorText: this.$t("cases.createDiagnosis.sfyycxf"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "implantAnchoragePlacement",
              inquireKey: "implant_anchorage_placement",
              superiorText: this.$t("cases.createDiagnosis.zssfjb"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "cbctPhotography",
              inquireKey: "cbct_photography",
              superiorText: this.$t("cases.createDiagnosis.zssfjbps"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "orthodonticsTreatmentBefore",
              inquireKey: "orthodontics_treatment_before",
              superiorText: this.$t("cases.createDiagnosis.hzsfyzjs"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar",
              ],
              superiorText: "17.3 " + this.$t("cases.createDiagnosis.jcyjjzfs"),
            },

            {
              identification: this.$t("cases.createDiagnosis.se"),
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar",
              ],
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml34 preview_pos_2"
                          : "preview_pos_8"
                      : this.needRdt
                          ? "ml38 preview_pos0"
                          : "preview_pos_8",
            },
            {
              filed: "upjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              parentName: "body_up",
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 preview_pos8"
                          : "ml38 mt12",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? "preview_pos8"
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },
            {
              filed: "upjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              parentName: "body_up",
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 preview_pos8"
                          : "ml38 mt12",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? "preview_pos8"
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },
            {
              filed: "upjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              parentName: "body_up",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? "preview_pos8"
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 preview_pos8"
                          : "ml38 mt12",
            },
            {
              filed: "upjawSideAnterior",
              inquireKey: "choose_option",
              superiorText:
                  this.$t("cases.createDiagnosis.lmqz") +
                  " - " +
                  this.$t("cases.createDiagnosis.qy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120"
                          : "ml60",

              parentName: "body_up",
            },
            {
              filed: "upjawSideRight",
              inquireKey: "choose_option",
              superiorText:
                  this.$t("cases.createDiagnosis.lmqz") +
                  " - " +
                  this.$t("cases.createDiagnosis.ychy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120"
                          : "ml60",

              parentName: "body_up",
            },
            {
              filed: "upjawSideLeft",
              inquireKey: "choose_option",
              superiorText:
                  this.$t("cases.createDiagnosis.lmqz") +
                  " - " +
                  this.$t("cases.createDiagnosis.zchy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120"
                          : "ml60",
              parentName: "body_up",
            },
            {
              filed: "upjawFar",
              inquireKey: "upjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymy"),
              parentName: "body_up",
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 mt12"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 preview_pos8"
                          : "ml38 mt12",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? ""
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },

            {
              identification: this.$t("cases.createDiagnosis.xe"),
              childOptions: [
                "downjawExt",
                "downjawLip",
                "downjawSide",
                "downjawSideAnterior",
                "downjawSideRight",
                "downjawSideLeft",
                "downjawFar",
              ],
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml34 preview_pos_2"
                          : "preview_pos_8"
                      : this.needRdt
                          ? "ml38 preview_pos_8"
                          : "preview_pos_8",
            },
            {
              filed: "downjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 mt12"
                          : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                  lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 mt12"
                          : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                  lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 mt12"
                          : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                  lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawSideAnterior",
              inquireKey: "choose_option",
              superiorText:
                  this.$t("cases.createDiagnosis.lmqz") +
                  " - " +
                  this.$t("cases.createDiagnosis.qy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 mt12"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 mt12"
                          : "ml38 mt12",
              parentName: "body_up",
            },
            {
              filed: "downjawSideRight",
              inquireKey: "choose_option",
              superiorText:
                  this.$t("cases.createDiagnosis.lmqz") +
                  " - " +
                  this.$t("cases.createDiagnosis.ychy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 mt12"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 mt12"
                          : "ml38 mt12",
              parentName: "body_up",
            },
            {
              filed: "downjawSideLeft",
              inquireKey: "choose_option",
              superiorText:
                  this.$t("cases.createDiagnosis.lmqz") +
                  " - " +
                  this.$t("cases.createDiagnosis.zchy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 mt12"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 mt12"
                          : "ml38 mt12",
              parentName: "body_up",
            },
            {
              filed: "downjawFar",
              inquireKey: "downjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 mt12"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 mt12"
                          : "ml38 mt12",
              parentName: "body_up",
            },

            {
              filed: "crowdCorrect",
              inquireKey: "crowd_correct",
            },
            {
              filed: "crowdCorrectSide",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              inquireKey: "choose_option_b",
              className: lang === "en_US" ? "ml34 mt12" : "ml38 mt12",
            },
            {
              key: "adjoin",
            },
          ],
        },
        {
          fIndex: 18,
          title: this.$t("cases.createDiagnosis.by"),
          key: "teeth",
          teethType: 4,
          type: "youth",
          pList: [
            {
              filed: "toothExtraction",
              key: "teeth",
              type: "youth",
              inquireKey: "tooth_extraction_options",
              teethType: 4,
            },
          ],
        },
        {
          fIndex: 19,
          title: this.$t("cases.createDiagnosis.txt16"),
          pList: [
            {
              filed: "underjawAbnormalFlag",
              inquireKey: "underjaw_abnormal_flag",
            },
            {
              filed: "underjawAbnormalFlagNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 20,
          title: this.$t("cases.createDiagnosis.sfxybdjzq"),
          pList: [
            {
              filed: "needPassiveAppliance",
              inquireKey: "need_passive_appliance",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "needPassiveApplianceNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 21,
          title: this.$t("cases.createDiagnosis.txt17"),
          pList: [
            {
              filed: "specialInstructions",
              type: "note",
            },
          ],
        },
      ];
    },
    babyList() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createPreview.zs"),
          pList: [
            {
              filed: "toothProblem",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem_c",
            },
            {
              filed: "jawProblem",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem_b",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "toothOtherProblem",
              type: "note",
            },
          ],
        },
        {
          fIndex: 2,
          title: this.$t("cases.createDiagnosis.zyjzmb"),
          pList: [
            {
              filed: "toothProblemTwo",
              inquireKey: "tooth_problem_two_c",
              superiorText: this.$t("cases.createPreview.ycwt"),
            },
            {
              filed: "jawProblemTwo",
              inquireKey: "jaw_problem_two",
              superiorText: this.$t("cases.createPreview.egwt"),
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "toothOtherProblemTwo",
              type: "note",
            },
          ],
        },
        // {
        //   fIndex: 3,
        //   title: this.$t('cases.createDiagnosis.txt2'),
        //   pList: [
        //     {
        //       filed: 'sagittalMaxillomandibularClassification',
        //       superiorText: this.$t('cases.createDiagnosis.gxfl'),
        //       inquireKey: 'sagittal_maxillomandibular_classification'
        //     },
        //     {
        //       filed: 'clinicInfo',
        //       inquireKey: 'clinic_info',
        //       superiorText: this.$t('cases.createDiagnosis.chlx'),
        //     },
        //     {
        //       tit: this.$t('cases.createDiagnosis.txt17'),
        //       filed: 'clinicOtherInfo',
        //       type: 'note'
        //     }
        //   ]
        // },
        {
          fIndex: 3,
          title: this.$t("cases.createDiagnosis.blxg"),
          pList: [
            {
              filed: "badHabits",
              inquireKey: "bad_habits",
            },
            {
              filed: "badHabitsNote",
              tit: this.$t("cases.createDiagnosis.txt17"),
              type: "note",
            },
          ],
        },
        {
          fIndex: 4,
          title: this.$t("cases.createDiagnosis.txt3"),
          pList: [
            {
              filed: "toCorrectToothJaw",
              inquireKey: "to_correct_tooth_jaw",
            },
            {
              filed: "toCorrectToothUpjaw",
              inquireKey: "to_correct_tooth_upjaw_b",
              sublevel: true,
            },
            {
              filed: "toCorrectToothDownjaw",
              inquireKey: "to_correct_tooth_downjaw_b",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 5,
          title: this.$t("cases.createDiagnosis.txt4"),
          key: "teeth",
          type: "children",
          pList: [
            {
              showTeeth: true,
              type: "children",
              key: "teeth",
              teethType: 1,
            },
          ],
        },
        {
          fIndex: 6,
          title: this.$t("cases.createDiagnosis.txt5"),
          key: "teeth",
          type: "children",
          pList: [
            {
              type: "children",
              key: "teeth",
              filed: "nonMoveableTeeth",
              inquireKey: "non_moveable_teeth",
              teethType: 2,
            },
          ],
        },
        {
          fIndex: 7,
          title: this.$t("cases.createDiagnosis.txt6"),
          key: "teeth",
          type: "children",
          pList: [
            {
              type: "children",
              key: "teeth",
              filed: "notAvailableAttachments",
              inquireKey: "not_available_for_attachments",
              teethType: 3,
            },
          ],
        },
        {
          fIndex: 8,
          title: this.$t("cases.createDiagnosis.txt7"),
          pList: [
            {
              filed: "sagittalRelation",
              inquireKey: "sagittal_relation",
            },
            {
              filed: "sagittalRelationMolar",
              inquireKey: "improve_molar",
              differentiateKey: "left",
              describeInputKey: "leftSagittalRelationMolarNote",
              sublevel: true,
            },
            {
              filed: "sagittalRelationMolar",
              inquireKey: "improve_molar",
              differentiateKey: "right",
              describeInputKey: "rightSagittalRelationMolarNote",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 9,
          title: this.$t("cases.createDiagnosis.hxgx"),
          pList: [
            {
              filed: "upHorizontalRelationship",
              inquireKey: "up_horizontal_relationship",
              secondLevel: this.$t("common.common.up"),
            },
            {
              filed: "upHorizontalRelationshipNote",
              inquireKey: "up_horizontal_expansion",
              describeInputKey: "upHorizontalRelationshipValue",
              isShowMM: true,
              sublevel: true,
            },

            {
              filed: "downHorizontalRelationship",
              inquireKey: "down_horizontal_relationship",
              secondLevel: this.$t("common.common.down"),
            },
            {
              filed: "downHorizontalRelationshipNote",
              inquireKey: "down_horizontal_expansion",
              describeInputKey: "downHorizontalRelationshipValue",
              isShowMM: true,
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 10,
          title: this.$t("cases.createDiagnosis.txt8"),
          pList: [
            {
              filed: "coverInfo",
              inquireKey: "cover_info",
            },
          ],
        },
        {
          fIndex: 11,
          title: this.$t("cases.createDiagnosis.txt9"),
          pList: [
            {
              filed: "overbiteInfo",
              inquireKey: "overbite_info",
            },

            {
              filed: "overbiteInfoOpen",
              inquireKey: "correct_open_b",
              sublevel: true,
              threeOptions: [
                {
                  key: "overbiteInfoExtrudeAnterior",
                  inquireKey: "updown_jaw",
                  parentKey: "extend_front_tooth",
                },
              ],
            },
            {
              filed: "overbiteInfoDeep",
              inquireKey: "correct_deep_cover_b",
              sublevel: true,
              threeOptions: [
                {
                  key: "overbiteInfoIntrudeAnterior",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front",
                },
                {
                  key: "overbiteInfoPosteriorsExrusion",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front_rise_back",
                },
              ],
            },

            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "overbiteOtherInfo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 12,
          title: this.$t("cases.createDiagnosis.qyfh"),
          pList: [
            {
              filed: "frontToothInfo",
              inquireKey: "front_tooth_info_b",
            },
            {
              filed: "correctFrontReverse",
              inquireKeys: [
                "correct_front_reverse",
                "correct_front_reverse_two",
              ],
              parentText: "",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 13,
          title: this.$t("cases.createDiagnosis.txt11"),
          pList: [
            {
              filed: "backToothInfo",
              inquireKey: "back_tooth_info",
            },
            {
              filed: "backToothInfoCorrect",
              inquireKey: "back_tooth_info_correct_b",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 14,
          title: this.$t("cases.createPreview.zx"),
          pList: [
            {
              filed: "middleLine",
              inquireKey: "middle_line",
            },
            {
              filed: "upjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "upjawMiddleLine",
              superiorText: this.$t("cases.createDiagnosis.se"),
              isShowMM: true,
              sublevel: true,
            },

            {
              filed: "downjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "downjawMiddleLine",
              isShowMM: true,
              superiorText: this.$t("cases.createDiagnosis.xe"),
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 15,
          title: this.$t("cases.createDiagnosis.txt13"),
          pList: [
            {
              tit: this.$t("cases.createDiagnosis.txt4"),
              filed: "teeth",
              key: "interval",
            },
          ],
        },
        {
          fIndex: 16,
          title: this.$t("cases.createPreview.yjzl"),
          pList: [
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar",
              ],
              // superiorText: this.$t('cases.createDiagnosis.jcyjjzfs'),
            },

            {
              identification: this.$t("cases.createDiagnosis.se"),
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar",
              ],
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml34 preview_pos_2"
                          : "preview_pos_8"
                      : this.needRdt
                          ? "ml38 preview_pos0"
                          : "preview_pos_8",
            },
            {
              filed: "upjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              parentName: "body_up",
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 preview_pos8"
                          : "ml38 mt12",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? "preview_pos8"
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },
            {
              filed: "upjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 preview_pos8"
                          : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? "preview_pos8"
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },
            {
              filed: "upjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              parentName: "body_up",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? "preview_pos8"
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 preview_pos8"
                          : "ml38 mt12",
            },
            {
              filed: "upjawSideAnterior",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.qy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml60",
              parentName: "body_up",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? ""
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },
            {
              filed: "upjawSideRight",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.ychy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml60",
              parentName: "body_up",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? ""
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },
            {
              filed: "upjawSideLeft",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.zchy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml60",
              parentName: "body_up",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? ""
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },
            {
              filed: "upjawFar",
              inquireKey: "upjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymry"),
              parentName: "body_up",
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 mt12"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 preview_pos8"
                          : "ml38 mt12",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? ""
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },

            {
              identification: this.$t("cases.createDiagnosis.xe"),
              childOptions: [
                "downjawExt",
                "downjawLip",
                "downjawSide",
                "downjawSideAnterior",
                "downjawSideRight",
                "downjawSideLeft",
                "downjawFar",
              ],
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml34 preview_pos_2"
                          : "preview_pos_8"
                      : this.needRdt
                          ? "ml38 preview_pos_8"
                          : "preview_pos_8",
            },
            {
              filed: "downjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 mt12"
                          : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                  lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 mt12"
                          : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                  lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 mt12"
                          : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                  lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawSideAnterior",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.qy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120"
                          : "ml60",
              parentName: "body_up",
            },
            {
              filed: "downjawSideRight",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.ychy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120"
                          : "ml60",
              parentName: "body_up",
            },
            {
              filed: "downjawSideLeft",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.zchy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120"
                          : "ml60",
              parentName: "body_up",
            },
            {
              filed: "downjawFar",
              inquireKey: "downjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymry"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 mt12"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 mt12"
                          : "ml38 mt12",
              parentName: "body_up",
            },
            {
              filed: "crowdCorrect",
              inquireKey: "crowd_correct",
            },
            {
              filed: "crowdCorrectSide",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              inquireKey: "choose_option_b",
              className: lang === "en_US" ? "ml34 mt12" : "ml38 mt12",
            },
            {
              key: "adjoin",
            },
          ],
        },
        {
          fIndex: 17,
          title: this.$t("cases.createDiagnosis.sfxyfzmy"),
          pList: [
            {
              filed: "eruptedCompensate",
              inquireKey: "erupted_compensate",
            },
            {
              filed: "eruptedCompensateNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 18,
          title: this.$t("cases.createDiagnosis.sfxybdjzq"),
          pList: [
            {
              filed: "needPassiveAppliance",
              inquireKey: "need_passive_appliance",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "needPassiveApplianceNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 19,
          title: this.$t("cases.createDiagnosis.txt17"),
          pList: [
            {
              filed: "specialInstructions",
              type: "note",
            },
          ],
        },
      ];
    },
    braceList() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createDiagnosis.exgjsfczyc"),
          pList: [
            {
              filed: "underjawAbnormalFlag",
              inquireKey: "underjaw_abnormal_flag",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "underjawAbnormalFlagNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 2,
          title: this.$t("cases.createDiagnosis.yzsfzc"),
          pList: [
            {
              filed: "periodontalDisease",
              inquireKey: "periodontal_disease",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "periodontalDiseaseNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 3,
          title: this.$t("cases.createDiagnosis.txt17"),
          pList: [
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "specialInstructions",
              type: "note",
            },
          ],
        },
      ];
    },
    veneerList() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createPreview.zs"),
          pList: [
            {
              filed: "toothProblem",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem",
            },
            {
              filed: "jawProblem",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem",
            },
            {
              filed: "toothOtherProblem",
              type: "note",
            },
          ],
        },
        {
          fIndex: 2,
          title: this.$t("cases.createDiagnosis.zyjzmb"),
          pList: [
            {
              filed: "toothProblemTwo",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem_two",
            },
            {
              filed: "jawProblemTwo",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem_two",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "toothOtherProblemTwo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 3,
          title: this.$t("cases.createDiagnosis.txt2"),
          pList: [
            {
              filed: "angleClassification",
              superiorText: this.$t("cases.createDiagnosis.asfl"),
              inquireKey: "angle_classification",
            },
            {
              filed: "sagittalMaxillomandibularClassification",
              superiorText: this.$t("cases.createDiagnosis.gxfl"),
              inquireKey: "sagittal_maxillomandibular_classification",
            },
            {
              filed: "clinicInfo",
              superiorText: this.$t("cases.createDiagnosis.chlx"),
              inquireKey: "clinic_info",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "clinicOtherInfo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 4,
          title: this.$t("cases.createPreview.njzye"),
          pList: [
            {
              filed: "toCorrectToothJaw",
              inquireKey: "to_correct_tooth_jaw",
            },
            {
              filed: "toCorrectToothUpjaw",
              inquireKey: "to_correct_tooth_upjaw",
              sublevel: true,
            },
            {
              filed: "toCorrectToothDownjaw",
              inquireKey: "to_correct_tooth_downjaw",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 5,
          title: this.$t("cases.createPreview.ycxx"),
          key: "teeth",
          teethType: 1,
          type: "adult",
          pList: [
            {
              showTeeth: true,
              type: "adult",
              key: "teeth",
              teethType: 1,
            },
          ],
        },
        {
          fIndex: 6,
          title: this.$t("cases.createDiagnosis.txt5"),
          teethType: 2,
          pList: [
            {
              filed: "nonMoveableTeeth",
              inquireKey: "non_moveable_teeth",
              type: "adult",
              key: "teeth",
              teethType: 2,
            },
          ],
        },
        {
          fIndex: 7,
          title: this.$t("cases.createDiagnosis.txt6"),
          key: "teeth",
          teethType: 3,
          type: "adult",
          pList: [
            {
              filed: "notAvailableAttachments",
              inquireKey: "not_available_for_attachments",
              type: "adult",
              key: "teeth",
              teethType: 3,
            },
          ],
        },

        {
          fIndex: 8,
          title: this.$t("cases.createPreview.jzgx"),
          pList: [
            {
              filed: "rightSagittalRelation",
              inquireKey: "right_sagittal_relation",
              secondLevel: this.$t("cases.createDiagnosis.you"),
            },
            {
              filed: "rightSagittalRelationNote",
              inquireKey: "improve_tip",
              threeOptions: [
                {
                  key: "rightSagittalRelationFar",
                  inquireKey: "precision_cut",
                  parentKey: "far_grind",
                },
                {
                  key: "rightSagittalRelationBitJump",
                  inquireKey: "precision_cut",
                  parentKey: "bit_jump",
                },
                {
                  key: "rightSagittalRelationExtraction",
                  inquireKey: "tooth_extraction",
                  parentKey: "tooth_extraction",
                },
              ],
              sublevel: true,
            },

            {
              filed: "leftSagittalRelation",
              inquireKey: "left_sagittal_relation",
              secondLevel: this.$t("cases.createPreview.zuo"),
            },
            {
              filed: "leftSagittalRelationNote",
              inquireKey: "improve_tip",
              threeOptions: [
                {
                  key: "leftSagittalRelationFar",
                  inquireKey: "precision_cut",
                  parentKey: "far_grind",
                },
                {
                  key: "leftSagittalRelationBitJump",
                  inquireKey: "precision_cut",
                  parentKey: "bit_jump",
                },
                {
                  key: "leftSagittalRelationExtraction",
                  inquireKey: "tooth_extraction",
                  parentKey: "tooth_extraction",
                },
              ],
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 9,
          title: this.$t("cases.createPreview.fg"),
          pList: [
            {
              filed: "coverInfo",
              inquireKey: "cover_info",
            },
          ],
        },
        {
          fIndex: 10,
          title: this.$t("cases.createPreview.fh"),
          pList: [
            {
              filed: "overbiteInfo",
              inquireKey: "overbite_info",
            },

            {
              filed: "overbiteInfoOpen",
              inquireKey: "correct_open",
              sublevel: true,
            },
            {
              filed: "overbiteInfoDeep",
              inquireKey: "correct_deep_cover",
              sublevel: true,
              threeOptions: [
                {
                  key: "overbiteInfoIntrudeAnterior",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front",
                },
                {
                  key: "overbiteInfoPosteriorsExrusion",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front_rise_back",
                },
              ],
            },

            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "overbiteOtherInfo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 11,
          title: this.$t("cases.createPreview.yd"),
          pList: [
            {
              filed: "frontToothInfo",
              inquireKey: "front_tooth_info",
            },

            {
              filed: "correctFrontReverse",
              inquireKeys: [
                "correct_front_reverse",
                "correct_front_reverse_two",
              ],
              parentText: this.$t("cases.createPreview.jzqyfh"),
              sublevel: true,
            },
            {
              filed: "correctFrontOpposite",
              inquireKeys: ["correct_front_opposite"],
              parentText: this.$t("cases.createPreview.jzqydrh"),
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 12,
          title: this.$t("cases.createPreview.hsh"),
          pList: [
            {
              filed: "backToothInfo",
              inquireKey: "back_tooth_info",
            },
            {
              filed: "backToothInfoCorrect",
              inquireKey: "back_tooth_info_correct",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 13,
          title: this.$t("cases.createPreview.zx"),
          pList: [
            {
              filed: "middleLine",
              inquireKey: "middle_line",
            },
            {
              filed: "upjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "upjawMiddleLine",
              superiorText: this.$t("cases.createDiagnosis.se"),
              isShowMM: true,
              sublevel: true,
            },

            {
              filed: "downjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "downjawMiddleLine",
              isShowMM: true,
              superiorText: this.$t("cases.createDiagnosis.xe"),
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 14,
          title: this.$t("cases.createPreview.jxzl"),
          pList: [
            {
              filed: "upjawGapCorrect",
              inquireKey: "updown_jaw_gap_correct",
              secondLevel: this.$t("cases.createPreview.she"),
            },
            {
              filed: "downjawGapCorrect",
              inquireKey: "updown_jaw_gap_correct",
              secondLevel: this.$t("cases.createPreview.xhe"),
            },
            {
              filed: "gapCorrect",
              inquireKey: "gap_correct",
              key: "reserve",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt4"),
              filed: "teeth",
            },
          ],
        },
        {
          fIndex: 15,
          title: this.$t("cases.createPreview.yjzl"),
          pList: [
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "acceptWisdomTeethExtraction",
                "acceptPremolarsExtraction",
                "acceptDecayedTeethExtraction",
                "acceptIpr",
              ],
              superiorText: "15.1 " + this.$t("cases.createDiagnosis.jcyjsx"),
            },
            {
              filed: "acceptWisdomTeethExtraction",
              inquireKey: "accept_wisdom_teeth_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbczc"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "acceptPremolarsExtraction",
              inquireKey: "accept_premolars_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbcqmy"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "acceptDecayedTeethExtraction",
              inquireKey: "accept_decayed_teeth_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbchy"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "acceptIpr",
              inquireKey: "accept_ipr",
              superiorText: this.$t("cases.createDiagnosis.hzsfjslmqy"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },

            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "undergoTeethRestoration",
                "implantAnchoragePlacement",
                "cbctPhotography",
                "orthodonticsTreatmentBefore",
              ],
              superiorText: "15.2 " + this.$t("cases.createDiagnosis.qtlczc"),
            },
            {
              filed: "undergoTeethRestoration",
              inquireKey: "undergo_teeth_restoration",
              superiorText: this.$t("cases.createDiagnosis.sfyycxf"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "implantAnchoragePlacement",
              inquireKey: "implant_anchorage_placement",
              superiorText: this.$t("cases.createDiagnosis.zssfjb"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "cbctPhotography",
              inquireKey: "cbct_photography",
              superiorText: this.$t("cases.createDiagnosis.zssfjbps"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "orthodonticsTreatmentBefore",
              inquireKey: "orthodontics_treatment_before",
              superiorText: this.$t("cases.createDiagnosis.hzsfyzjs"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar",
              ],
              superiorText: "15.3 " + this.$t("cases.createDiagnosis.jcyjjzfs"),
            },

            {
              identification: this.$t("cases.createDiagnosis.se"),
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar",
              ],
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml34 preview_pos_2"
                          : "preview_pos_8"
                      : this.needRdt
                          ? "ml38 preview_pos0"
                          : "preview_pos_8",
            },
            {
              filed: "upjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              parentName: "body_up",
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 preview_pos8"
                          : "ml38 mt12",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? "preview_pos8"
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },
            {
              filed: "upjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 preview_pos8"
                          : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? "preview_pos8"
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },
            {
              filed: "upjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              parentName: "body_up",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? "preview_pos8"
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 preview_pos8"
                          : "ml38 mt12",
            },
            {
              filed: "upjawSideAnterior",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.qy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml60",
              parentName: "body_up",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? ""
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },
            {
              filed: "upjawSideRight",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.ychy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml60",
              parentName: "body_up",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? ""
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },
            {
              filed: "upjawSideLeft",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.zchy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml60",
              parentName: "body_up",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? ""
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },
            {
              filed: "upjawFar",
              inquireKey: "upjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymy"),
              parentName: "body_up",
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 mt12"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 preview_pos8"
                          : "ml38 mt12",
              answerClass:
                  lang === "en_US"
                      ? this.needRdt
                          ? ""
                          : ""
                      : this.needRdt
                          ? "preview_pos8"
                          : "",
            },

            {
              identification: this.$t("cases.createDiagnosis.xe"),
              childOptions: [
                "downjawExt",
                "downjawLip",
                "downjawSide",
                "downjawSideAnterior",
                "downjawSideRight",
                "downjawSideLeft",
                "downjawFar",
              ],
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml34 preview_pos_2"
                          : "preview_pos_8"
                      : this.needRdt
                          ? "ml38 preview_pos_8"
                          : "preview_pos_8",
            },
            {
              filed: "downjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 mt12"
                          : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                  lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 mt12"
                          : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                  lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 preview_pos8"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 mt12"
                          : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                  lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawSideAnterior",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.qy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120"
                          : "ml60",
              parentName: "body_up",
            },
            {
              filed: "downjawSideRight",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.ychy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120"
                          : "ml60",
              parentName: "body_up",
            },
            {
              filed: "downjawSideLeft",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.zchy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml150"
                          : "ml120"
                      : this.needRdt
                          ? "ml120"
                          : "ml60",
              parentName: "body_up",
            },
            {
              filed: "downjawFar",
              inquireKey: "downjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymy"),
              className:
                  lang === "en_US"
                      ? this.needRdt
                          ? "ml120 mt12"
                          : "ml90 mt12"
                      : this.needRdt
                          ? "ml70 mt12"
                          : "ml38 mt12",
              parentName: "body_up",
            },

            {
              filed: "crowdCorrect",
              inquireKey: "crowd_correct",
              className: lang === "en_US" ? "ml34 mt12" : "ml38 mt12",
            },
            {
              filed: "toothExtraction",
              key: "teeth",
              teethType: 4,
              type: "adult",
              superiorText: this.$t("cases.createDiagnosis.by"),
              inquireKey: "tooth_extraction_options",
            },
          ],
        },
        {
          fIndex: 16,
          title: this.$t("cases.createPreview.sfphz"),
          pList: [
            {
              filed: "growNailFlag",
              inquireKey: "grow_nail_flag",
            },
            {
              filed: "upGrowNailPosition",
              inquireKey: "grow_nail_position",
              superiorText:
                  "16.1 " +
                  this.$t("cases.createDiagnosis.nzrzzkd") +
                  " - " +
                  this.$t("cases.createDiagnosis.se"),
            },
            {
              filed: "downGrowNailPosition",
              inquireKey: "grow_nail_position",
              superiorText:
                  "16.1 " +
                  this.$t("cases.createDiagnosis.nzrzzkd") +
                  " - " +
                  this.$t("cases.createDiagnosis.xe"),
            },
            {
              filed: "growNailPurpose",
              inquireKey: "grow_nail_purpose",
              superiorText:
                  "16.2 " + this.$t("cases.createDiagnosis.nzrzzkdzy"),
            },
            {
              filed: "growNailFlagNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 17,
          title: this.$t("cases.createPreview.hgj"),
          pList: [
            {
              filed: "underjawAbnormalFlag",
              inquireKey: "underjaw_abnormal_flag",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "underjawAbnormalFlagNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 18,
          title: this.$t("cases.createDiagnosis.sfxybdjzq"),
          pList: [
            {
              filed: "needPassiveAppliance",
              inquireKey: "need_passive_appliance",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "needPassiveApplianceNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 19,
          title: this.$t("cases.createDiagnosis.xfsj"),
        },
        {
          fIndex: 19.1,
          title:
              this.$t("cases.createDiagnosis.xfsj") +
              " - " +
              this.$t("cases.createDiagnosis.sxyw"),
          pList: [
            {
              veneer: "veneer",
              showVeneer: true,
              type: "veneer",
              teethType: 10,
            },
          ],
        },
        {
          fIndex: 19.2,
          title:
              this.$t("cases.createDiagnosis.xfsj") +
              " - " +
              this.$t("cases.createDiagnosis.yysx"),
          pList: [
            {
              filed: "originalTeethColor",
              inquireKey: "original_teeth_color",
            },
            {
              filed: "originalTeethColorNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 19.3,
          title:
              this.$t("cases.createDiagnosis.xfsj") +
              " - " +
              this.$t("cases.createDiagnosis.mbys"),
          pList: [
            {
              filed: "targetColor",
              inquireKey: "target_color",
            },
            {
              filed: "targetColorNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 19.4,
          title:
              this.$t("cases.createDiagnosis.xfsj") +
              " - " +
              this.$t("cases.createDiagnosis.ymwl"),
          pList: [
            {
              filed: "surfaceTexture",
              inquireKey: "surface_texture",
            },
          ],
        },
        {
          fIndex: 19.5,
          title:
              this.$t("cases.createDiagnosis.xfsj") +
              " - " +
              this.$t("cases.createDiagnosis.sjyq"),
          pList: [
            {
              filed: "designRequestOne",
              inquireKey: "design_request_one",
            },
            {
              filed: "designRequestTwo",
              inquireKey: "design_request_two",
            },
          ],
        },
        {
          fIndex: 20,
          title: this.$t("cases.createPreview.tssm"),
          pList: [
            {
              filed: "specialInstructions",
              type: "note",
            },
          ],
        },
      ];
    },
    yysdList() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createDiagnosis.ycxxqy"),
          pList: [
            {
              showYysd: true,
              teethType: 1,
            },
          ],
        },
        {
          fIndex: 2,
          title: this.$t("cases.createDiagnosis.ycxxjy"),
          pList: [
            {
              showYysd: true,
              teethType: 11,
            },
          ],
        },
        {
          fIndex: 3,
          title: this.$t("cases.createDiagnosis.lczk"),
          pList: [
            {
              filed: "clinicInfo",
              superiorText: this.$t("cases.createDiagnosis.chlx"),
              inquireKey: "clinic_info_f",
            },
            {
              filed: "clinicOtherInfo",
              type: "note",
            },
            {
              filed: "occlusalRelationship",
              superiorText: this.$t("cases.createDiagnosis.yhgx"),
              inquireKey: "occlusal_relationship",
            },
            {
              filed: "adjacentGap",
              superiorText: this.$t("cases.createDiagnosis.ljx"),
              inquireKey: "adjacent_gap",
            },
            {
              filed: "toothProblemThree",
              superiorText: this.$t("cases.createDiagnosis.ycwt"),
              inquireKey: "tooth_problem_three",
            },
            {
              filed: "toothOtherProblemThree",
              type: "note",
            },
          ],
        },
        {
          fIndex: 4,
          title: this.$t("cases.createDiagnosis.sxyw"),
          pList: [
            {
              showYysd: true,
              childKey: true,
            },
            {
              filed: "desiredToothPosition",
              type: "note",
            },
          ],
        },
        {
          fIndex: 5,
          title: this.$t("cases.createDiagnosis.yysx"),
          pList: [
            {
              filed: "originalTeethColor",
              inquireKey: "original_teeth_color_f",
            },
            {
              filed: "colorSystem",
              inquireKeys: ["ivoclar", "vita"],
              describeInputKey: "colorNumber",
              isShowMM: false,
            },
            {
              filed: "originalTeethColorNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 6,
          title: this.$t("cases.createDiagnosis.mbyase"),
          pList: [
            {
              filed: "targetColor",
              inquireKey: "target_color",
            },
            {
              filed: "targetColorNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 7,
          title: this.$t("cases.createDiagnosis.mblx"),
          pList: [
            {
              filed: "targetType",
              inquireKey: "target_type",
            },
            {
              filed: "targetTypeNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 8,
          title: this.$t("cases.createDiagnosis.mbtmd"),
          pList: [
            {
              filed: "targetTransparency",
              inquireKey: "target_transparency",
            },
          ],
        },
        {
          fIndex: 9,
          title: this.$t("cases.createDiagnosis.cllx"),
          pList: [
            {
              filed: "materialType",
              inquireKey: "material_type",
            },
          ],
        },
        {
          fIndex: 10,
          title: this.$t("cases.createDiagnosis.sfby"),
          pList: [
            {
              filed: "whetherToPrepareTeeth",
              inquireKey: "whether_to_prepare_teeth",
              type: "yysd",
              teethType: 14,
              showYysd: true,
            },
          ],
        },
        {
          fIndex: 11,
          title: this.$t("cases.createDiagnosis.tmhdyq"),
          pList: [
            {
              filed: "veneerThicknessRequirements",
              value: true,
            },
          ],
        },
        {
          fIndex: 12,
          title: this.$t("cases.createDiagnosis.ymwl"),
          pList: [
            {
              filed: "surfaceTexture",
              inquireKey: "surface_texture",
            },
          ],
        },
        {
          fIndex: 13,
          title: this.$t("cases.createDiagnosis.sjyq"),
          pList: [
            {
              filed: "designRequestOne",
              inquireKey: "design_request_one_f",
            },
            {
              filed: "designRequestTwo",
              inquireKey: "design_request_two_f",
            },
          ],
        },
        {
          fIndex: 14,
          title: this.$t("cases.createDiagnosis.txt17"),
          pList: [
            {
              filed: "specialInstructions",
              type: "note",
            },
          ],
        },
      ];
    },
  },
  methods: {
    isBoolean,
    submitCase() {
      this.$emit("submitCase");
    },
    goBackQueer(type) {
      this.$emit("saveDiagnosis", type);
    },
    saveData() {
      if (this.curePlanId) {
        this.isLoading = true;
        return doctorSubmitCurePlan(this.curePlanId)
            .then(() => {
              notifyMsg(
                  this,
                  "success",
                  this.$t("cases.createPreview.tjcg")
              ).then(() => {
                this.isLoading = false;
                this.$router.push("/cases");
              });
            })
            .catch((err) => {
              if (err && err.errorCode === "AIS00128") {
                this.goBackQueer("clear");
              }
              this.isLoading = false;
              this.$emit("resetSubStatus");
            });
      } else {
        return Promise.reject();
      }
    },
    goClinical() {
      localStorage.setItem("refType", "case");
      localStorage.setItem("refId", this.queryCaseId);
      localStorage.setItem("backUrl", "/cases/create");
      sessionStorage.setItem("isShowRTD", false);
      this.$router.push("/cases/clinicalPreferences");
    },
    groupClick(item, type) {
      const tit = item;
      let key = split(tit);
      let optionsObj = {
        A: this.diagnosisObj,
        B: this.youthDiagnosisObj,
        C: this.babyDiagnosisObj,
        D: this.braceDiagnosisObj,
        E: this.veneerDiagnosisObj,
        F: this.yysdDiagnosisObj,
        G: this.youthDiagnosisObj,
        H: this.youthDiagnosisObj,
        I: this.gapDiagnososObj,
      }[this.$route.query.productType];
      if (tit && type === "1") {
        sessionStorage.setItem("diagnosisKey", optionsObj[key[0]]);
      }
      // if(tit && type === '2') {
      // sessionStorage.setItem('diagnosisKey', optionsObj[key[0]]);
      // }
      if (type) {
        this.$emit("saveDiagnosis", type);
      }
    },
    getDiaOption() {
      this.casesData = {
        A: this.adult,
        B: this.youthList,
        C: this.babyList,
        D: this.braceList,
        E: this.veneerList,
        F: this.yysdList,
        G: this.youthList,
        H: this.youthList,
        I: this.gap,
      }[this.$route.query.productType];
    },
  },
  props: {
    isSubmit: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  beforeDestroy() {
    this.$emit("oneJumpFour", true);
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    let id = this.queryCurePlanId;
    let caseId = this.queryCaseId;
    if (caseId && !id) {
      this.queer = false;
      return this.$emit("oneJumpFour", false);
    }
    if (id) {
      toCompleteCurePlan({id, type: 'cure_plan'})
          .then((obj) => {
            this.previewNo = {
              ...this.previewNo,
              ...obj,
            };
          })
          .then(() => {
            this.curePlanId = id;
            detailCurePlan(id).then((obj) => {
              this.details = obj;
              this.userInfo = obj.caseInfo.userInfo;
              const toothInfoList = obj.toothInfoList;
              if (Array.isArray(toothInfoList)) {
                const babyObj = {};
                const missingObj = {}; // missingTeethKeyObj
                const noMoveObj = {}; // noMoveTeethKeyObj
                const noDesignObj = {}; // noDesignTeethKeyObj
                const extractionObj = {}; // extractionTeethKeyObj
                toothInfoList.forEach((item) => {
                  if (item.type === "1") {
                    missingObj[item.number] = {
                      type: "1",
                      number: item.number,
                      className: "teeth_missing",
                    };
                  } else if (item.type === "2") {
                    noMoveObj[item.number] = {
                      type: "2",
                      number: item.number,
                      className: "teeth_no_move",
                    };
                  } else if (item.type === "3") {
                    noDesignObj[item.number] = {
                      type: "3",
                      number: item.number,
                      className: "teeth_no_design",
                    };
                  } else if (item.type === "4") {
                    extractionObj[item.number] = {
                      type: "4",
                      number: item.number,
                      className: "teeth_extraction",
                    };
                  } else if (item.type === "7") {
                    babyObj[item.number] = {
                      id: item.id,
                      type: "7",
                      number: item.number,
                    };
                  }
                });
                this.teethKeyObj = {
                  missingTeethKeyObj: missingObj,
                  noMoveTeethKeyObj: extend({}, missingObj, noMoveObj),
                  noDesignTeethKeyObj: extend({}, missingObj, noDesignObj),
                  extractionTeethKeyObj: extend(
                      {},
                      missingObj,
                      extractionObj,
                      noMoveObj
                  ),
                };
                this.babyTeethKeyObj = babyObj;
              }

              if (this.$route.query.productType === "F") {
                const data = this.details;
                let lossTeeth = filter(
                    data.toothInfoList,
                    (item) => item.type === "1"
                );
                let dentureTeeth = filter(
                    data.toothInfoList,
                    (item) => item.type === "11"
                );
                let veneerTeeth = filter(
                    data.toothInfoList,
                    (item) => item.type === "10"
                );
                let crownTeeth = filter(
                    data.toothInfoList,
                    (item) => item.type === "12"
                );
                let inlayTeeth = filter(
                    data.toothInfoList,
                    (item) => item.type === "13"
                );
                let grindTeeth = filter(
                    data.toothInfoList,
                    (item) => item.type === "14"
                );
                let untreatedList = [
                  ...lossTeeth,
                  ...dentureTeeth,
                  ...veneerTeeth,
                  ...crownTeeth,
                  ...inlayTeeth,
                  ...grindTeeth,
                ];
                let mainList = adultTeethList;
                if (untreatedList.length) {
                  let teethTypeObj = {
                    1: "LOSS", // 缺失牙
                    10: "VENEER", // 贴面
                    11: "DENTURE", // 假牙
                    12: "CROWN", // 冠
                    13: "INLAY", // 嵌体
                    14: "GRIND", // 备牙
                  };
                  this.commonTeeth = filter(
                      map(mainList, (item) => {
                        let obj;
                        each(untreatedList, (it) => {
                          if (item.number === it.number) {
                            obj = extend({}, item, obj, {
                              [teethTypeObj[it.type]]: teethTypeObj[it.type],
                            });
                          }
                        });
                        return obj ? obj : item;
                      })
                  );
                } else {
                  this.commonTeeth = [];
                }
                return;
              }

              this.uploadIntervalList = filter(
                  this.details.toothInfoList,
                  (item) => item.type === "5"
              );
              this.crowdTeethList = filter(
                  this.details.toothInfoList,
                  (item) => item.type === "9"
              );

              let noMoveList = filter(
                  this.details.toothInfoList,
                  (item) => item.type === "2"
              );
              let deficiencyList = map(
                  filter(this.details.toothInfoList, (item) => item.type === "1"),
                  (it) => extend({}, it, {deficiency: "deficiency"})
              );
              let noDesignList = filter(
                  this.details.toothInfoList,
                  (item) => item.type === "3"
              );
              let removeList = filter(
                  this.details.toothInfoList,
                  (item) => item.type === "4"
              );
              let commonVeneerList = filter(
                  this.details.toothInfoList,
                  (item) => item.type == "10"
              );
              if (commonVeneerList.length) {
                this.commonVeneerList = commonVeneerList;
              }
              let mainList = ["A", "E", "I"].includes(
                  this.$route.query.productType
              )
                  ? filter(
                      this.details.toothInfoList,
                      (item) => item.type === "7"
                  ).concat(adultTeethList)
                  : filter(this.details.toothInfoList, (item) => item.type === "8");
              let untreatedList = [
                ...noMoveList,
                ...noDesignList,
                ...removeList,
                ...deficiencyList,
              ];
              if (
                  untreatedList.length ||
                  ["A", "E", "I"].includes(this.$route.query.productType)
              ) {
                let teethTypeObj = {
                  2: "noMove",
                  3: "noDesign",
                  4: "remove",
                  1: "deficiency",
                };
                this.commonTeeth = map(mainList, (item) => {
                  let obj;
                  each(untreatedList, (it) => {
                    if (item.number === it.number && !item.nextNumber) {
                      obj = extend({}, item, obj, {
                        [teethTypeObj[it.type]]: teethTypeObj[it.type],
                      });
                    }
                  });
                  return obj ? obj : item;
                });
              } else {
                this.commonTeeth =
                    filter(
                        this.details.toothInfoList,
                        (item) => !item.nextNumber
                    ) || [];
              }
            });
            this.getDiaOption();
          });
    }
  },
  components: {
    FullLoading,
    PublicCasesUser,
    PublicCasesDiagnosis,
    PublicCasesPic,
    PreviewDiagnosis,
    PublicTeethModel,
  },
  watch: {
    isSubmit(val) {
      if (val) {
        this.saveData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create_preview {
  background-color: #fff;
  padding: 0.33rem 0.3rem 0.3rem 0.3rem;

  .preview_box_content {
    padding-left: 30px;
  }

  .preview_tip {
    .p_tip_ul {
      width: 80%;
      margin-top: 0.67rem;

      .p_tip_li {
        padding-left: 30px;
        margin-bottom: 60px;

        .p_tip_l_h {
          display: flex;
          align-items: center;
          position: relative;
          font-size: 0.18rem;
          color: $main_theme_color_333;
        }

        .l_h_sp {
          color: $main_theme_color;
          font-size: 0.3rem;
          position: absolute;
          left: -0.06rem;
          top: 50%;
          transform: translateY(-50%);
        }

        .p_tip_l_box {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          box-sizing: border-box;
          margin-top: 10px;

          span {
            margin-bottom: 0.1rem;
            border-bottom: 0.01rem solid $main_theme_color_333;
            color: $main_theme_color_333;
            cursor: pointer;
            margin-right: 0.2rem;
            font-size: 0.16rem;
          }

          span:hover {
            color: $main_theme_color;
            border-bottom: 0.01rem solid $main_theme_color;
          }
        }

        &:last-child {
          .p_tip_l_box {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  .tip_h5 {
    display: flex;
    align-items: center;
    font-size: 0.22rem;
    color: $main_theme_color_333;

    .tip_icon {
      display: inline-block;
      width: 0.24rem;
      height: 0.24rem;
      background-image: url("/static/images/createCases/web_preview_tips.png");
      background-size: 100% 100%;
      border-radius: 100%;
      margin-right: 0.1rem;
    }

    .tip_icon_ok {
      background-image: url("../../../common/imgs/case/case_preview_success.png");
    }
  }

  .preview_box {
    .preview_box_ul {
      margin-top: 0.3rem;
    }
  }

  .to_clinical {
    color: $main_theme_color_333;
    font-size: 0.16rem;
    cursor: pointer;
    margin-right: 0.1rem;
    text-decoration: underline;
  }

  .preview_foo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.queer {
  display: flex;
  margin-top: 0.3rem;
  padding-left: 0.45rem;

  span {
    font-size: 0.2rem;
  }

  span:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.submit_btn {
  width: 176px;
  height: 42px;
  background: $main_theme_color;
  border-radius: 6px;
}
</style>
