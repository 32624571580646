<template>
  <div class="address-card">
    <div class="address-info">
      <div class="user-info">
        <div :title="info.sender" class="user-name">{{ info.sender }}</div>
        <div>
          {{
            info.contactWay === "2"
              ? `${info.phoneArea}-${info.phone}`
              : info.phone
          }}
        </div>
        <div v-if="showDefault && info.defaultFlag === '1'" class="is-default">
          默认
        </div>
      </div>
      <div :title="renderAddress()" class="address">{{ renderAddress() }}</div>
    </div>
    <div class="option">
      <div class="edit" @click="confirm">
        {{ isCreated ? $t("birdie.xz") : $t("birdie.bj") }}
      </div>
      <div class="remove" @click="remove">
        {{ isCreated ? $t("birdie.bj") : $t("cases.cases.sc") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isCreated: {
      required: false
    },
    showDefault: {
      required: false,
      default: false
    },
    info: {
      required: true
    }
  },
  methods: {
    renderAddress() {
      const { provinceName, cityName, areaName, address } = this.info;
      return `${provinceName}-${cityName}-${areaName}-${address}`;
    },
    confirm() {
      this.$emit("confirm", this.info);
    },
    remove() {
      this.$emit("cancel", this.info);
    }
  }
};
</script>

<style lang="scss" scoped>
.address-card {
  box-sizing: border-box;
  width: 100%;
  height: 95%;
  padding: 20px;
  padding-right: 0px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(51, 51, 51, 0.12);
  display: flex;

  .address-info {
    width: 70%;
    padding-right: 12px;
    border-right: 1px solid #e5e5e5;

    .user-info {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 22px;
      height: 24px;
      color: #333333;
      margin-bottom: 10px;

      div {
        white-space: nowrap;
      }
    }

    .user-name {
      max-width: 25%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 8px;
    }

    .address {
      font-size: 14px;
      line-height: 20px;
      color: #666666;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .is-default {
      margin-left: 8px;
      width: 48px;
      height: 24px;
      font-size: 12px;
      color: #ed2047;
      border-radius: 12px;
      border: 1px solid #ed2047;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .option {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .edit,
    .remove {
      cursor: pointer;
      width: 96px;
      height: 36px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: #333333;
    }

    .edit {
      background-color: $main_theme_color;
      color: #fff;
    }
  }
}
</style>
