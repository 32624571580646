<template>
  <div class="table_wrap">
    <div class="left_option">
      <div
        v-for="(item, index) in leftList"
        :key="index"
        :class="{ left_active: selectLeftKey === `d${index + 1}` }"
        class="left_option_item"
        @click="selectLeft(index)"
      >
        <div v-show="selectLeftKey === `d${index + 1}`" class="left_wire"></div>
        {{ item.title }}
      </div>
    </div>
    <div ref="rightTable" class="right_table">
      <div v-if="problemList && problemList.length > 0" class="dia_problem">
        <h5>{{ $t("cases.createImage.czrxwt") }}</h5>
        <div class="pro_box">
          <span
            v-for="(item, index) in problemList"
            :key="`${item.id}_${index}`"
            >{{ item.groupNo }} {{ item.groupName }}</span
          >
        </div>
      </div>
      <div id="1" class="select_type">
        <div class="flex-y-c mb10">
          <div class="mr4">
            <span class="main_theme_color_red fz14">●</span>
          </div>
          <p class="fz16 main_theme_color_333">
            1.{{ $t("cases.createDiagnosis.exgjsfczyc") }}
          </p>
          <p class="main_theme_color_999 fz14 ml20">
            {{ $t("cases.createDiagnosis.exgjsfczycinit") }}
          </p>
        </div>
        <p v-if="diagnosisProblem['1']" class="problem_note">
          {{ diagnosisProblem["1"] }}
        </p>
        <div class="flex-y-c">
          <span
            v-for="(item, index) in caseOptions['underjaw_abnormal_flag']"
            :key="index"
            :class="{
              is_active: item.key === diagnosisParams.underjawAbnormalFlag.key
            }"
            class="common_btn"
            @click="
              levelOneClick({
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'underjawAbnormalFlag'
                }
              })
            "
            >{{ item.value }}</span
          >
        </div>
        <div
          v-show="diagnosisParams.underjawAbnormalFlag.key === 'true'"
          class="remark_box"
        >
          <el-input
            v-model="diagnosisParams.underjawAbnormalFlagNote"
            :autosize="{ minRows: 4, maxRows: 6 }"
            :disabled="isBackDisabled"
            :placeholder="$t('common.common.qsrnr')"
            class="tex_b_a"
            maxlength="2000"
            show-word-limit
            type="textarea"
          ></el-input>
        </div>
      </div>
      <div id="2" class="select_type">
        <div class="flex-y-c mb10">
          <div class="mr4">
            <span class="main_theme_color_red fz14">●</span>
          </div>
          <p class="fz16 main_theme_color_333">
            2.{{ $t("cases.createDiagnosis.yzsfzc") }}
          </p>
          <p class="main_theme_color_999 fz14 ml20">
            {{ $t("cases.createDiagnosis.yzsfzcinit") }}
          </p>
        </div>
        <p v-if="diagnosisProblem['2']" class="problem_note">
          {{ diagnosisProblem["2"] }}
        </p>
        <div class="flex-y-c">
          <span
            v-for="(item, index) in caseOptions['underjaw_abnormal_flag']"
            :key="index"
            :class="{
              is_active: item.key === diagnosisParams.periodontalDisease.key
            }"
            class="common_btn"
            @click="
              levelOneClick({
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'periodontalDisease'
                }
              })
            "
            >{{ item.value }}</span
          >
        </div>
        <div
          v-show="diagnosisParams.periodontalDisease.key === 'true'"
          class="remark_box"
        >
          <el-input
            v-model="diagnosisParams.periodontalDiseaseNote"
            :autosize="{ minRows: 4, maxRows: 6 }"
            :disabled="isBackDisabled"
            :placeholder="$t('common.common.qsrnr')"
            class="tex_b_a"
            maxlength="2000"
            show-word-limit
            type="textarea"
          ></el-input>
        </div>
      </div>
      <div id="3" class="select_type">
        <p class="fz16 main_theme_color_333">
          3.{{ $t("cases.createDiagnosis.txt17") }}
        </p>
        <p v-if="diagnosisProblem['3']" class="problem_note">
          {{ diagnosisProblem["3"] }}
        </p>

        <div class="remark_box">
          <el-input
            v-model="diagnosisParams.specialInstructions"
            :autosize="{ minRows: 4, maxRows: 6 }"
            :disabled="isBackDisabled"
            :placeholder="$t('common.common.qsrnr')"
            class="tex_b_a"
            maxlength="2000"
            show-word-limit
            type="textarea"
          ></el-input>
        </div>
      </div>
    </div>
    <full-loading v-show="isLoading" />
  </div>
</template>

<script>
import {
  extend,
  isArray,
  each,
  values,
  get,
  map,
  filter,
  pickBy,
  isObject,
  keys,
  isNumber,
  some
} from "lodash";
import { getDictAllList } from "common/api/public";
import { notifyMsg } from "common/js/util";
import {
  createCurePlan,
  editCurePlan,
  detailCurePlan,
  cureProblemList
} from "common/api/cases";
import FullLoading from "components/full-loading/full-loading";
import $ from "jquery";
import { changeRouteQuery } from "../../../common/js/Fn";

let elementResizeDetectorMaker = require("element-resize-detector");

export default {
  data() {
    return {
      caseOptions: {},
      diaKeyObj: {},
      details: {},
      diagnosisParams: {
        //1
        underjawAbnormalFlag: {},
        underjawAbnormalFlagNote: "",

        //2
        periodontalDisease: {},
        periodontalDiseaseNote: "",

        //3
        specialInstructions: ""
      },
      lang: localStorage.getItem("user_lang") || "zh_CN",
      selectLeftKey: "1",
      isLoading: false,
      leftList: [
        {
          title: this.$t("cases.createDiagnosis.exgjsfczyc")
        },
        {
          title: this.$t("cases.createDiagnosis.yzsfzc")
        },
        {
          title: this.$t("cases.createDiagnosis.txt17")
        }
      ],
      isScroll: false,
      problemList: [],
      diagnosisProblem: {},

      isResponse: 0,
      leftHeight: 0,
      isProductErr: false
    };
  },
  components: {
    FullLoading
  },
  computed: {
    queryCaseId() {
      return this.$route.query.caseId;
    },
    queryCurePlanId() {
      return this.$route.query.curePlanId;
    },
    isBackDisabled() {
      return false;
      return get(this.details, "status") === "to_resubmit" && this.isProductErr;
    }
  },
  methods: {
    values,
    get,
    map,
    selectLeft(key) {
      if (!key) return;
      let list = key.split("d");
      this.isScroll = false;
      let leftStr;
      if (list.length < 2) {
        leftStr = `${key + 1}`;
        document.documentElement.scrollTop =
          key === 0 ? 0 : document.getElementById(key).offsetTop;
      } else {
        leftStr = key;
        document.documentElement.scrollTop = document.getElementById(
          list[1]
        ).offsetTop;
      }
      this.selectLeftKey = "d" + leftStr;
      setTimeout(() => {
        this.isScroll = true;
      }, 1000);
    },
    // 一级点
    levelOneClick({ addItem, multipleRadio, radio = true }) {
      let { key, submitKey } = addItem;
      if (!key || this.isBackDisabled) return;

      if (
        ["underjawAbnormalFlag", "periodontalDisease"].includes(submitKey) &&
        key === "false"
      ) {
        let objKey = {
          underjawAbnormalFlag: "underjawAbnormalFlagNote",
          periodontalDisease: "periodontalDiseaseNote"
        };
        this.$set(this.diagnosisParams, objKey[submitKey], "");
      }
      // 单选
      if (radio) {
        if (this.diagnosisParams[submitKey].key === key) {
          return (this.diagnosisParams[submitKey] = {});
        }
        if (this.diagnosisParams[submitKey].key === key) return;
        this.$set(this.diagnosisParams, submitKey, addItem);
      } else {
        if (isArray(this.diagnosisParams[submitKey]["children"])) {
          let itx;
          each(this.diagnosisParams[submitKey]["children"], (item, index) => {
            if (item.key === key) {
              itx = index;
            }
          });
          if (itx !== undefined) {
            return this.diagnosisParams[submitKey]["children"].splice(itx, 1);
          }

          this.diagnosisParams[submitKey]["children"].push(addItem);
        } else {
          this.$set(this.diagnosisParams[submitKey], "children", []);
          this.diagnosisParams[submitKey]["children"].push(addItem);
        }
      }
    },
    getCaseOptions() {
      let id = this.queryCurePlanId;
      this.isLoading = true;
      getDictAllList([
        // 获取病例选项的值
        "underjaw_abnormal_flag"
      ]).then(data => {
        this.isLoading = false;
        let dictObj = {},
          diaKeyObj = {};
        data.forEach(item => {
          if (!dictObj[item.parentKey]) {
            dictObj[item.parentKey] = [item];
          } else {
            dictObj[item.parentKey].push(item);
          }
          diaKeyObj[item.key] = item.value;
        });
        this.diaKeyObj = diaKeyObj;
        this.caseOptions = dictObj;
        this.diagnosisParams.caseId = this.queryCaseId || "";
        if (id) {
          this.diagnosisParams.id = id;
          detailCurePlan(id).then(obj => {
            this.details = obj;
            this.initParams(obj).then(() => {
              setTimeout(() => {
                this.isResponse = 0;
              }, 800);
            });
          });
          cureProblemList({
            caseId: this.diagnosisParams.caseId,
            curePlanId: id
          }).then(data => {
            const diagnosisProblem = {};
            data.forEach(item => {
              diagnosisProblem[item.groupNo] = item.content;
            });
            this.problemList = data.filter(
              item => +item.groupNo < 4 && item.groupNo > 0
            );

            this.isProductErr = this.problemList.length
              ? false
              : !some(data, item => +item.groupNo === 0);

            this.diagnosisProblem = diagnosisProblem;
            setTimeout(() => {
              if ($(".dia_problem").length > 0) {
                this.pEleHeight = $(".dia_problem").height() + 30;
              }
            }, 100);
          });
        } else {
          setTimeout(() => {
            this.isResponse = 0;
          }, 800);
        }
      });
    },
    saveData() {
      let dia = this.diagnosisParams;
      let obj = {};
      for (let i in dia) {
        if (isObject(dia[i]) && keys(dia[i]).length) {
          // 一级对象
          if (dia[i].key) {
            obj[i] = dia[i].key;
          }
        }
      }
      let {
        periodontalDiseaseNote,
        underjawAbnormalFlagNote,
        specialInstructions
      } = dia;
      let imgObj = pickBy({
        sideFacePicture: dia.sideFacePicture, // 侧面像
        frontFacePicture: dia.frontFacePicture, // 正面像
        smilePicture: dia.smilePicture, // 微笑像
        upToothPicture: dia.upToothPicture, // 上牙列像
        downToothPicture: dia.downToothPicture, // 下牙列像
        mouthRightPicture: dia.mouthRightPicture, // 口内右侧位像
        mouthFrontPicture: dia.mouthFrontPicture, // 口内正位像
        mouthLeftPicture: dia.mouthLeftPicture, // 口内左侧位像
        xrayCurvePicture: dia.xrayCurvePicture, // 全颌曲面断层片
        xraySidePicture: dia.xraySidePicture, // 头颅侧位定位片
        otherPicture: dia.otherPicture, // 其他影像资料
        anteriorOverjetPicture: dia.anteriorOverjetPicture //前牙覆盖像
      });
      let PicObj = {};
      for (const item in imgObj) {
        if (item !== "otherPicture") {
          PicObj[item] = JSON.stringify(this.isJSON(imgObj[item]));
        }
      }
      obj = extend(
        {},
        obj,
        PicObj,
        pickBy({
          caseId: dia.caseId,
          id: dia.id,
          periodontalDiseaseNote,
          underjawAbnormalFlagNote,
          specialInstructions,
          digitalModelFile: dia.digitalModelFile, //数字模型
          siliconeRubberModel: dia.siliconeRubberModel // 硅橡胶模型
        })
      );
      this.isLoading = true;
      if (!dia.id) {
        return createCurePlan(obj)
          .then(data => {
            this.isLoading = false;
            this.diagnosisParams.id = data;
            notifyMsg(this, "success", this.$t("common.message.yzc"), 2500);
            changeRouteQuery(this, { curePlanId: data });
            return Promise.resolve();
          })
          .catch(() => {
            this.isLoading = false;
            this.$emit("saveError");
            return Promise.reject();
          });
      } else {
        return editCurePlan(obj)
          .then(() => {
            this.isLoading = false;
            notifyMsg(this, "success", this.$t("common.message.yzc"), 2500);
            changeRouteQuery(this, { curePlanId: obj.id });
            return Promise.resolve();
          })
          .catch(() => {
            this.isLoading = false;
            this.$emit("saveError");
            return Promise.reject();
          });
      }
    },
    isJSON(str) {
      if (str == "") {
        return "";
      }
      if (typeof str == "string") {
        try {
          JSON.parse(str);
          let obj = JSON.parse(str);
          if (!obj.nanoId) {
            obj.nanoId = obj.ETag;
          }
          return obj;
        } catch (error) {
          return { ETag: str, nanoId: str };
        }
      }
    },
    initOption({ type, submitKey, addItem }) {
      // type: 1 一级对象, 2 数组
      let data = this.details;
      let valueData = this.diaKeyObj;
      let dia = this.diagnosisParams;
      if (data[submitKey]) {
        if (type === 1) {
          dia[submitKey] = {
            submitKey,
            key: data[submitKey],
            value: valueData[data[submitKey]]
          };
        } else if (type === 2) {
          dia[submitKey] = {
            children: map(
              data[submitKey] && data[submitKey].split(","),
              item => {
                return { key: item, value: valueData[item], submitKey };
              }
            )
          };
        } else if (type === 3) {
          this.$set(this.diagnosisParams, submitKey, addItem);
        }
      }
    },
    initParams(data) {
      const diagnsisLeftKey = sessionStorage.getItem("diagnosisKey") || "d1";
      this.selectLeft(diagnsisLeftKey);

      let dia = this.diagnosisParams;
      let imgObj = pickBy({
        sideFacePicture: data.sideFacePicture, // 侧面像
        frontFacePicture: data.frontFacePicture, // 正面像
        smilePicture: data.smilePicture, // 微笑像
        upToothPicture: data.upToothPicture, // 上牙列像
        downToothPicture: data.downToothPicture, // 下牙列像
        mouthRightPicture: data.mouthRightPicture, // 口内右侧位像
        mouthFrontPicture: data.mouthFrontPicture, // 口内正位像
        mouthLeftPicture: data.mouthLeftPicture, // 口内左侧位像
        xrayCurvePicture: data.xrayCurvePicture, // 全颌曲面断层片
        xraySidePicture: data.xraySidePicture, // 头颅侧位定位片
        otherPicture: data.otherPicture, // 其他影像资料
        anteriorOverjetPicture: data.anteriorOverjetPicture, //前牙覆盖像

        digitalModelFile: data.digitalModelFile, // 数字文件
        siliconeRubberModel: data.siliconeRubberModel // 硅橡胶模型
      });
      extend(this.diagnosisParams, imgObj);

      //1
      this.initOption({ type: 1, submitKey: "underjawAbnormalFlag" });
      dia.underjawAbnormalFlagNote = data.underjawAbnormalFlagNote;

      //2
      this.initOption({ type: 1, submitKey: "periodontalDisease" });
      dia.periodontalDiseaseNote = data.periodontalDiseaseNote;

      //3
      if (data["specialInstructions"]) {
        dia.specialInstructions = data.specialInstructions;
      }

      return Promise.resolve();
    }
  },
  mounted() {
    let erd = elementResizeDetectorMaker();
    erd.listenTo(this.$refs["rightTable"], element => {
      this.leftHeight = element.offsetHeight;
    });
  },
  created() {
    this.isResponse += 1;
    this.getCaseOptions();
  }
};
</script>

<style lang="scss" scoped>
.left_active {
  color: $main_theme_color !important;
}

.table_wrap {
  display: flex;

  .left_option {
    width: 2.4rem;
    height: 6.6rem;
    background-color: #fff;
    position: fixed;

    .left_option_item {
      width: 100%;
      height: 0.5rem;
      transition: all 0.2s;
      font-size: 0.16rem;
      color: $main_theme_color_333;
      cursor: pointer;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .left_wire {
        width: 4px;
        height: 32px;
        background: $main_theme_color;
        position: absolute;
        left: 0;
      }
    }

    .left_option_item:hover {
      transition: all 0.2s;
      background: #f9f9f9;
    }
  }

  .right_table {
    margin-left: 2.4rem;
    width: calc(100% - 2.4rem);
    background-color: #fff;
    padding: 0.3rem 0.2rem 0.5rem 0.59rem;
    border-left: 1px solid #e5e5e5;
    margin-bottom: -2rem;
    height: 6.6rem;

    .select_type {
      margin-bottom: 0.3rem;

      .select_type_title {
        font-size: 0.16rem;
        color: $main_theme_color_333;
      }
    }
  }
}

.common_btn {
  position: relative;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.2rem;
  color: $main_theme_color_333;
  border-radius: 4px;
  border: 0.01rem solid #bbbbbb;
  cursor: pointer;
  font-size: 0.14rem;
  margin-bottom: 0.1rem;
  white-space: nowrap;
  user-select: none;
  background-color: #fff;
}

.is_active {
  background-color: $main_theme_color;
  color: #fff !important;
  border: 0.01rem solid $main_theme_color;
}

.remark_box {
  width: 100%;
  margin-top: 0.1rem;

  textarea {
    width: 90%;
    border: 0.01rem solid #c8c8c8;
    border-radius: 0.08rem;
    padding: 0.2rem;
    color: #333;
    min-height: 1.34rem;
  }
}

.option_left_title {
  min-width: 0.7rem;
}

.single_tip_box {
  margin-top: 0.2rem;
  color: $main_theme_color_333;
  background-color: #f4f4f4;
  font-size: 0.14rem;
  margin-bottom: 0.2rem;
  padding: 0.15rem 0.2rem;

  p {
    margin-bottom: 0.1rem;

    &:last-child {
      margin-bottom: 0;
    }

    input {
      width: 100%;
      outline: none;
      border: none;
    }
  }
}

.popover_con_right_iup {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.2rem;
  border: 0.01rem solid #c8c8c8;
  width: 80%;
  height: 0.4rem;
  border-radius: 0.06rem;
  padding-right: 0.17rem;

  input {
    padding: 0.13rem 0 0.13rem 0.15rem;
    font-size: 0.14rem;
    flex: 1;
    outline: none;
    border: none;
  }
}

.single_con {
  color: #333;
  font-size: 0.16rem;
  position: relative;

  .remark_box {
    width: 100%;

    textarea {
      width: 100%;
      border: 0.01rem solid #c8c8c8;
      border-radius: 0.08rem;
      padding: 0.2rem;
      color: #333;
      min-height: 1.34rem;
    }
  }

  .lc_box {
    background-color: #f9f9f9;
    padding: 0.3rem 0.2rem 0.16rem 0.3rem;
    display: flex;
    justify-content: space-between;
    font-size: 0.14rem;
    color: #333;

    .check_box {
      flex: 1;
    }

    .el-checkbox-group {
      display: flex;
      flex: 1;
      flex-wrap: wrap;

      .check_single {
        margin: 0 0.1rem 0.2rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .other_box {
      width: 2.81rem;

      .tit {
        margin-bottom: 0.12rem;
      }

      textarea {
        width: 100%;
        height: 0.98rem;
        border-radius: 0.04rem;
        background-color: #fff;
        padding: 0.1rem;
        box-sizing: border-box;
      }
    }
  }

  .single_c_head {
    margin-bottom: 0.3rem;
  }

  .s_c_box {
    display: flex;

    .s_c_b_left {
      margin-right: 0.2rem;
      margin-top: 0.13rem;
    }

    .t_b_p {
      color: #666;
      min-width: 0.64rem;
    }

    .t_b_tip {
      color: #666;
      font-size: 0.14rem;
      margin-left: 0.1rem;
    }
  }
}

.t_b_box {
  display: flex;
  align-items: center;
  font-size: 0.16rem;
  flex-wrap: wrap;
  margin-bottom: 0.16rem;
  line-height: 0.2rem;
}

.popover_con {
  display: flex;

  .popover_con_left {
    min-width: 0.3rem;
    white-space: nowrap;
    padding-top: 0.15rem;
  }

  .popover_con_right {
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    span {
      margin-top: 0.1rem;
      margin-bottom: 0.1rem;
    }

    .popover_con_right_p {
      margin-bottom: 0.1rem;
    }

    .popover_con_right_iup {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 0.2rem;
      border: 0.01rem solid #c8c8c8;
      width: 80%;
      height: 0.4rem;
      border-radius: 0.06rem;
      padding-right: 0.17rem;

      input {
        padding: 0.13rem 0 0.13rem 0.15rem;
        font-size: 0.14rem;
        flex: 1;
        outline: none;
        border: none;
      }
    }
  }
}

.el-scrollbar__wrap {
  overflow-x: hidden;
}

.dia_problem {
  width: 100%;
  background: #f4f4f4;
  padding: 10px 16px 10px;
  margin-bottom: 30px;

  h5 {
    font-size: 0.22rem;
    color: $main_theme_color_333;
  }

  span {
    margin-right: 0.2rem;
  }

  .pro_box {
    word-break: break-all;
    font-size: 0.16rem;
    line-height: 0.3rem;
    color: $main_theme_color_333;
  }
}

.problem_note {
  word-break: break-all;
  color: $main_theme_color;
  margin-bottom: 0.2rem;
  line-height: 0.22rem;
  font-size: 0.16rem;
}
</style>
